import { useMemo } from "react"
import styled from "styled-components"
import {
    Button, Descriptions as Desc,
    message,
    Popconfirm,
    Table
} from "antd"
import { useMutation, useQuery } from "@apollo/client"
import { Link, useParams } from "react-router-dom"
import IMask from "imask"
import moment from "moment"

import { Top, LoadingView, Empty, Image } from "../../components"
import { FIND_UNIQUE_USER, FIND_MANY_REVIEW, DELETE_ONE_REVIEW, USER_STATS } from "../../gqls"
import { useRouteQuery, useNavigateSearch } from "../../utils/hooks"

const Descriptions = styled(Desc)`
    max-width: 600px;
    font-size: 14px;

    .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        padding: 0;
        object-fit: cover;
    }
    .image-list {
        display: flex;
    }
    .image {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
`
const Controls = styled.div`
    margin-top: 15px;
    margin-bottom: 24px;
`

const maskedPhone = IMask.createMask({
    mask: '+7 (000) 000-00-00'
})

const limit = 10

const SingleClient = () => {
    const { id } = useParams()
    const query = useRouteQuery()
    const navigate = useNavigateSearch()

    const { page = 1 } = query

    const { data, loading } = useQuery(FIND_UNIQUE_USER, {
        variables: {
            where: { id }
        }
    })

    const { data: statsData, loading: statsLoading } = useQuery(USER_STATS, {
        variables: {
            where: {
                id
            }
        }
    })

    const { data: reviewData, loading: reviewLaoding, refetch } = useQuery(FIND_MANY_REVIEW, {
        variables: {
            where: {
                order: {
                    clientId: { equals: id }
                },
                author: { equals: "babySitter" }
            },
            take: limit,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        }
    })

    const [deleteComment, { loading: deleteLoading }] = useMutation(DELETE_ONE_REVIEW, {
        onCompleted: () => {
            message.success("Комментарий удален")
            refetch()
        },
        onError: e => { }
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/client/${id}`, { ...query, page: current })
    }

    const client = useMemo(() => data ? data.findUniqueUser : null, [data])
    const reviews = useMemo(() => reviewData ? reviewData.findManyReview : [], [reviewData])
    const reviewCount = useMemo(() => reviewData ? reviewData.findManyReviewCount : 0, [reviewData])
    const ordersCount = useMemo(() => statsData ? statsData.userStats._count.id : 0, [statsData])
    const summPrice = useMemo(() => statsData ? statsData.userStats._sum.price : 0, [statsData])

    return (
        <>
            <Top title={'Информация об клиенте'} />
            {
                client ? (
                    <>
                        <Descriptions
                            title="Информация"
                            bordered
                            column={1}
                            layout='vertical'
                            labelStyle={{
                                fontWeight: 600
                            }}
                        >
                            <Descriptions.Item label='Аватар'>
                                <Image
                                    src={`/uploads/${client.avatar}`}
                                    className='avatar'
                                    alt={client.avatar}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='ФИО'>
                                {client.name}
                            </Descriptions.Item>
                            <Descriptions.Item label='Номер телефона'>
                                <a href={`tel:${client.phone}`}>
                                    {maskedPhone.resolve(client.phone)}
                                </a>
                            </Descriptions.Item>
                            <Descriptions.Item label='Дата рождения'>
                                {client.birthday ? moment(client.birthday).format("DD.MM.YYYY") : "-"}
                            </Descriptions.Item>
                            <Descriptions.Item label='Выбранный город'>
                                {client?.city?.title}
                            </Descriptions.Item>
                            <Descriptions.Item label='Ранг'>
                                {client?.league}
                            </Descriptions.Item>
                            <Descriptions.Item label={`Дети (${client.childrens.length})`}>
                                {
                                    client.childrens.length > 0 ? client.childrens.map(item => (
                                        <div>{item.name}, {moment(item.birthday).fromNow(true)}</div>
                                    )) : "-"
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label='Рейтинг'>
                                {parseFloat(client.rating).toFixed(2)}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label='Количество заказов'>
                                {client._count.orders}
                            </Descriptions.Item> */}
                            <Descriptions.Item label='Количество выполненных заказов'>
                                {ordersCount} / {client._count.orders}
                            </Descriptions.Item>
                            <Descriptions.Item label='Сумма осуществленных заказов'>
                                {summPrice} 🪙 
                            </Descriptions.Item>
                            <Descriptions.Item label='Платформа моб приложения'>
                                {client?.appPlatform ? client.appPlatform : 'Нет данных...'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Версия моб приложения'>
                                {client?.appVersion ? client.appVersion : 'Нет данных...'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Пуш уведомления'>
                                {client?.pushEnabled ? "Включен" : 'Отключен'}
                            </Descriptions.Item>
                        </Descriptions>
                        <Controls>
                            <Link to={`/client/edit/${id}`}>
                                <Button type='primary'>
                                    Редактировать
                                </Button>
                            </Link>
                        </Controls>
                        <Top title={"Оценки пользователя"} />
                        <Table
                            loading={reviewLaoding || deleteLoading}
                            rowKey={(obj) => obj.id}
                            dataSource={reviews}
                            scroll={{ x: 600 }}
                            size={window.innerWidth < 500 ? 'small' : 'large'}
                            pagination={{
                                current: parseInt(page),
                                total: reviewCount,
                                pageSize: limit
                            }}
                            onChange={handleChangeTable}
                            columns={[
                                {
                                    title: 'ФИО автора',
                                    dataIndex: 'babySitter',
                                    key: 'babySitter',
                                    render: (babySitter, obj) => (
                                        <Link to={`/baby-sitter/${babySitter.id}`}>
                                            {babySitter.name} {babySitter.surname}
                                        </Link>
                                    ),
                                },
                                {
                                    title: 'Оценка',
                                    dataIndex: 'rating',
                                    key: 'rating',
                                },
                                {
                                    title: 'Текст',
                                    dataIndex: 'text',
                                    key: 'text',
                                    render: text => text ? text : '-'
                                },
                                {
                                    title: 'Дата создание',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    render: (createdAt, obj) => (
                                        <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                                    )
                                },
                                {
                                    title: 'Действие',
                                    width: 150,
                                    render: (_, object) => (
                                        <Popconfirm
                                            title={`Удалить комментарий?`}
                                            onConfirm={() => {
                                                deleteComment({
                                                    variables: {
                                                        where: {
                                                            id: object.id,
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <Button
                                                type={'danger'}
                                            >
                                                {"Удалить"}
                                            </Button>
                                        </Popconfirm>
                                    )
                                }
                            ]}
                        />
                    </>
                ) : (
                    <Empty
                        description="Клиент не найден"
                        buttonText='Назад'
                        onButtonClick={() => window.history.back()}
                    />
                )
            }
            <LoadingView loading={loading || statsLoading} />
        </>
    )
}

export default SingleClient
import { gql } from "@apollo/client"

export const FIND_UNIQUE_DOCUMENT = gql`
	query(
		$where: DocumentWhereUniqueInput!
	){
		findUniqueDocument(
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					orders
					reviews
					location
					pushIds
				}
			}
			babySitterId
			image
			date
			type
		}
	}
`
export const FIND_FIRST_DOCUMENT = gql`
	query(
		$where: DocumentWhereInput
		$orderBy: [DocumentOrderByWithRelationInput]
		$cursor: DocumentWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [DocumentScalarFieldEnum]
	){
		findFirstDocument(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			block
			delete
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					orders
					reviews
					location
					pushIds
				}
			}
			babySitterId
			image
			date
			type
		}
	}
`
export const FIND_MANY_DOCUMENT = gql`
	query(
		$where: DocumentWhereInput
		$orderBy: [DocumentOrderByWithRelationInput]
		$cursor: DocumentWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [DocumentScalarFieldEnum]
	){
		findManyDocument(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			block
			delete
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					orders
					reviews
					location
					pushIds
				}
			}
			babySitterId
			image
			date
			type
		}
	}
`
export const CREATE_ONE_DOCUMENT = gql`
	mutation(
		$data: DocumentCreateInput!
	){
		createOneDocument(
			data: $data
		){
			id
			createdAt
			updatedAt
			block
			delete
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					orders
					reviews
					location
					pushIds
				}
			}
			babySitterId
			image
			date
			type
		}
	}
`
export const UPDATE_ONE_DOCUMENT = gql`
	mutation(
		$data: DocumentUpdateInput!
		$where: DocumentWhereUniqueInput!
	){
		updateOneDocument(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					orders
					reviews
					location
					pushIds
				}
			}
			babySitterId
			image
			date
			type
		}
	}
`
export const DELETE_ONE_DOCUMENT = gql`
	mutation(
		$where: DocumentWhereUniqueInput!
	){
		deleteOneDocument(
			where: $where
		){
			id
			createdAt
			updatedAt
		}
	}
`
export const UPSERT_ONE_DOCUMENT = gql`
	mutation(
		$where: DocumentWhereUniqueInput!
		$create: DocumentCreateInput!
		$update: DocumentUpdateInput!
	){
		upsertOneDocument(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			block
			delete
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					orders
					reviews
					location
					pushIds
				}
			}
			babySitterId
			image
			date
			type
		}
	}
`
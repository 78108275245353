import { gql } from "@apollo/client"

export const FIND_UNIQUE_BABY_SITTER = gql`
	query(
		$where: BabySitterWhereUniqueInput!
	){
		findUniqueBabySitter(
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
	}
`
export const FIND_FIRST_BABY_SITTER = gql`
	query(
		$where: BabySitterWhereInput
		$orderBy: [BabySitterOrderByWithRelationInput]
		$cursor: BabySitterWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [BabySitterScalarFieldEnum]
	){
		findFirstBabySitter(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
	}
`
export const FIND_MANY_BABY_SITTER = gql`
	query(
		$where: BabySitterWhereInput
		$orderBy: [BabySitterOrderByWithRelationInput]
		$cursor: BabySitterWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [BabySitterScalarFieldEnum]
	){
		findManyBabySitter(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
		findManyBabySitterCount(
			where: $where
		)
	}
`
export const CREATE_ONE_BABY_SITTER = gql`
	mutation(
		$data: BabySitterCreateInput!
	){
		createOneBabySitter(
			data: $data
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
	}
`
export const UPDATE_ONE_BABY_SITTER = gql`
	mutation(
		$data: BabySitterUpdateInput!
		$where: BabySitterWhereUniqueInput!
	){
		updateOneBabySitter(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
	}
`
export const DELETE_ONE_BABY_SITTER = gql`
	mutation(
		$where: BabySitterWhereUniqueInput!
	){
		deleteOneBabySitter(
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
	}
`
export const UPSERT_ONE_BABY_SITTER = gql`
	mutation(
		$where: BabySitterWhereUniqueInput!
		$create: BabySitterCreateInput!
		$update: BabySitterUpdateInput!
	){
		upsertOneBabySitter(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			surname
			middlename
			birthday
			league
			school
			insurance
			videoCard
			about
			advantage
			avatar
			rating
			status
			rejectMessage
			working
			balance
			documents{
				id
				createdAt
				updatedAt
				block
				delete
				babySitterId
				image
				date
				type
			}
			user{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				city{
					id
					title
				}
				appVersion
				appPlatform
				pushEnabled
			}
			_count {
				orders
			}
			userId
		}
	}
`
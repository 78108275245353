import { useMemo } from "react"
import styled from "styled-components"
import {
    Button,
    Table,
    Form as AntForm,
    Select,
    Input,
    message,
    Tag,
    Popover,
    Popconfirm
} from 'antd'
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"
// import IMask from 'imask'

import { Top } from '../../components'
import { FIND_MANY_ORDER, UPDATE_ONE_ORDER } from "../../gqls"
import { useRouteQuery, useNavigateSearch } from "../../utils/hooks"
// import { ADMIN_TYPES } from "../../utils/const"

const Filters = styled(AntForm)`
    margin-bottom: 20px;

    .item {
        width: 200px;
    }
`

// const maskedPhone = IMask.createMask({
//     mask: '+7 (000) 000-00-00'
// })

const limit = 20

const ActiveStreams = () => {
    const query = useRouteQuery()
    const { page = 1, search = "", status = undefined } = query
    const navigate = useNavigateSearch()

    const variables = useMemo(() => {
        const variables = { where: {
            streaming:{
                equals:true
            },
            status: { equals: "confirmed" },
        } }
        if (search) {
            variables.where = {
                streaming:{
                    equals:true
                },
                status: { equals: "confirmed" },
                OR: [
                    {
                        client: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        client: {
                            phone: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            surname: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            middlename: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            user: {
                                phone: { contains: search, mode: 'insensitive' }
                            }
                        }
                    },
                ]
            }
        }
        if (status) {
            variables.where = {
                ...variables.where,
                status: { equals: "confirmed" }
            }
        }
        return variables
    }, [search, status])

    const [updateOrder, { loading: updateLoading }] = useMutation(UPDATE_ONE_ORDER, {
        onCompleted: () => {
            message.success("Заказ отменен")
        }
    })

    const { data, loading } = useQuery(FIND_MANY_ORDER, {
        variables: {
            ...variables,
            take: limit,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/active-streams`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, status }) => {
        navigate("/active-streams", { ...query, search, status })
    }

    const renderStatus = (status, order) => {
        let text = 'Новый'
        let color = 'yellow'
        if (status === 'cancelledBySitter') {
            text = 'Отменен няней'
            color = 'red'
        }
        if (status === 'cancelledByClient') {
            text = 'Отменен клиентом'
            color = 'red'
        }
        if (status === 'canceled') {
            text = 'Отменен'
            color = 'red'
        }
        if (status === 'complated') {
            text = 'Выполнен'
            color = 'green'
        }
        if (status === 'confirmed') {
            text = 'Подтвержден'
            color = 'green'
        }
        if (status === 'new') {
            return (
                <Popover
                    content={
                        <Popconfirm
                            title='Отменить заказ?'
                            onConfirm={() => {
                                updateOrder({
                                    variables: {
                                        where: { id: order.id },
                                        data: {
                                            status: { set: "canceled" }
                                        }
                                    }
                                })
                            }}
                        >
                            <Button danger>
                                Отменить заказ
                            </Button>
                        </Popconfirm>
                    }
                >
                    <Tag style={{ cursor: "pointer" }} color={color}>{text}</Tag>
                </Popover>
            )
        }
        return <Tag color={color}>{text}</Tag>
    }

    const orders = useMemo(() => data ? data.findManyOrder : [], [data])
    const orderCount = useMemo(() => data ? data.findManyOrderCount : 0, [data])

    return (
        <>
            <Top
                title={`Подтвержденные заказы с активными стримами (${orderCount})`}
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    status
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/active-streams", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
            </Filters>
            <Table
                loading={loading || updateLoading}
                rowKey={(obj) => obj.id}
                dataSource={orders}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: parseInt(page),
                    total: orderCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'Клиент',
                        dataIndex: 'client',
                        key: 'client',
                        render: (client, obj) => (
                            <Link to={`/client/${client.id}`}>
                                {client.name}
                            </Link>
                        )
                    },
                    {
                        title: 'Няня',
                        dataIndex: 'babySitter',
                        key: 'babySitter',
                        render: (babySitter, obj) => babySitter ? (
                            <Link to={`/baby-sitter/${babySitter.id}`}>
                                {babySitter.name} {babySitter.surname} {babySitter.middlename}
                            </Link>
                        ) : 'Исполнитель не найден'
                    },
                    {
                        title: 'Цена',
                        dataIndex: 'price',
                        key: 'price',
                        render: (price) => (
                            <span>{price} 🪙</span>
                        )
                    },
                    {
                        title: 'Время',
                        dataIndex: 'from',
                        key: 'from',
                        render: (from, obj) => (
                            <span>{moment(from).format("DD.MM.yyyy, HH:mm")} - {moment(obj.to).format("HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Город',
                        dataIndex: 'city',
                        key: 'city'
                    },
                    {
                        title: 'Адрес',
                        dataIndex: 'address',
                        key: 'address'
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: renderStatus
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Оффера',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => (
                            <a href={`/offer/${id}`} target="_blank">Посмотреть оффера</a>
                        )
                    },
                    {
                        title: 'Перейти на стрим',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => (
                            <a href={`/room/${id}?role=slave`} target="_blank">Посмотреть стрим</a>
                        )
                    },
                    // https://podprismotrom-ykt.ru/room/cljcgeg0p5549k7kqb4e1zwb0?role=owner
                ]}
            />
        </>
    )
}

export default ActiveStreams
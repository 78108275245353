import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu as AntMenu } from 'antd'
import { useUser } from '../utils/hooks'

export const Menu = () => {
    const { pathname } = window.location
    const { user } = useUser()

    return (
        <AntMenu theme="dark" mode="inline" defaultSelectedKeys={[pathname]}>
            <AntMenu.Item key={`/`}>
                <MenuLink to={`/`}>
                    Главная
                </MenuLink>
            </AntMenu.Item>
            {user.role.canClients &&
            <AntMenu.Item key={`/client`}>
                <MenuLink to={`/client`}>
                    Клиенты
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canSitters &&
            <AntMenu.Item key={`/baby-sitter`}>
                <MenuLink to={`/baby-sitter`}>
                    Няни
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canRequests &&
            <AntMenu.Item key={`/request`}>
                <MenuLink to={`/request`}>
                    Заявки
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canHelp &&
            <AntMenu.Item key={`/faq`}>
                <MenuLink to={`/faq`}>
                    faq
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canHelp &&
            <AntMenu.Item key={`/offer-comments`}>
                <MenuLink to={`/offer-comments`}>
                    Комменты к офферу
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canHelp &&
            <AntMenu.Item key={`/cancel-reasons`}>
                <MenuLink to={`/cancel-reasons`}>
                    Причины отмены
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canHelp &&
            <AntMenu.Item key={`/help`}>
                <MenuLink to={`/help`}>
                    Помощь
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canSettings &&
            <AntMenu.Item key={`/settings`}>
                <MenuLink to={`/settings`}>
                    Настройки
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canOrders &&
            <AntMenu.Item key={`/order`}>
                <MenuLink to={`/order`}>
                    Заказы
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canOrders &&
            <AntMenu.Item key={`/orderfavorite`}>
                <MenuLink to={`/orderfavorite`}>
                    Заказы избранные
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canOrders &&
            <AntMenu.Item key={`/active-streams`}>
                <MenuLink to={`/active-streams`}>
                    Активные стримы
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canSettings &&
            <AntMenu.Item key={`/city`}>
                <MenuLink to={`/city`}>
                    Города
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canNotification &&
            <AntMenu.Item key={`/push`}>
                <MenuLink to={`/push`}>
                    Уведомление
                </MenuLink>
            </AntMenu.Item>
            }
            {user.role.canChangeRole &&
            <AntMenu.Item key={`/admins`}>
                <MenuLink to={`/admins`}>
                    Администраторы
                </MenuLink>
            </AntMenu.Item>
            }
        </AntMenu>
    )
}

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`

import { useEffect } from 'react'
import { Button, Form, message, Select, Input } from 'antd'
import {Top} from '../../components/Top'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../utils/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { DELETE_ONE_CITY, FIND_UNIQUE_CITY, UPDATE_ONE_CITY } from '../../gqls/city'
const { Option } = Select

const UpdateAdmin = () => {
  // const { id } = match.params
  const { user, loading: loadingMe } = useUser()

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.role?.canSettings) navigate(-1)
  })

  const { data, loading: loadingCity } = useQuery(FIND_UNIQUE_CITY, {
    fetchPolicy: 'network-only',
    variables: { where: { id: id } },
  })

  const onCompleted = () => navigate(-1)

  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [updateOneCity, { loading }] = useMutation(UPDATE_ONE_CITY, {
    onCompleted,
    onError,
  })

  const [deleteOneCity, { loading: loadingDel }] = useMutation(DELETE_ONE_CITY, {
    onCompleted,
    onError,
  })

  const handleCreate = (v) => {
    updateOneCity({
      variables: {
        where: { id },
        data: {
          title: { set: v.title ? v.title : city.title },
          description: { set: v.description ? v.description : city.description },
          costStatus: { set: v.costStatus ? v.costStatus : city.costStatus },
        },
      },
    })
  }

  const deleteCity = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneCity({ variables: { where: { id } } })
  }

  if (loadingMe || loadingCity) return 'Загрузка...'
  if (!user?.role?.canSettings) return 'Ошибка'
  const city = data?.findUniqueCity

  return (
    <>
      <Top title='Изменить данные' />
      <div style={{ maxWidth: 500 }}>
        <Form initialValues={{ ...city }} onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Название'>
            <Input />
          </Form.Item>
          <Form.Item label='Описание' name='description'>
            <Input />
          </Form.Item>
          <Form.Item label='Статус цен' name='costStatus'>
            <Input />
          </Form.Item>
          <Button loading={loading || loadingDel} htmlType='submit' type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel} onClick={deleteCity} type='primary'>
            Удалить
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdateAdmin

import { useEffect } from 'react'
import { Button, Form, message, Input, Select } from 'antd'
import {Top} from '../../components/Top'
import { useMutation } from '@apollo/client'
import { useUser } from '../../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { CREATE_ONE_FAQ, CREATE_ONE_OFFER_COMMENTS } from '../../gqls'

const { Option } = Select
const requiredRule = { required: true }

const CreateOfferComments = () => {
  const { user, loading: loadingMe } = useUser()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user?.role?.canHelp) navigate(-1)
  })

  const [createOneFAQ, { loading }] = useMutation(CREATE_ONE_OFFER_COMMENTS, {
    onCompleted: () => navigate(-1),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const handleCreate = (v) => {
    createOneFAQ({ variables: { data: v } })
  }

  if (loadingMe) return 'Загрузка...'
  if (!user?.role?.canHelp) return 'Ошибка'
  return (
    <>
      <Top title='Добавить комментарий к офферу' />
      <div style={{ maxWidth: 700 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          {/* <Form.Item name='title' label='Название' rules={[requiredRule]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' label='Описание'>
            <TextArea rows={4} />
          </Form.Item>
          <h3>Права:</h3> */}
          <Form.Item label="Коммент" rules={[requiredRule]} name='title'>
            <Input ></Input>
          </Form.Item>

          <Button loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateOfferComments

import React, { useEffect } from 'react';

const Agreement = () => {
    useEffect(()=>{
        window.location.replace('https://podprismotrom-ykt.ru/pdf/applyProcessPersonalData.docx.pdf');
    },[])

  return (
    <div style={{height:"100%", width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
        <a href='https://podprismotrom-ykt.ru/pdf/applyProcessPersonalData.docx.pdf' style={{fontWeight:'600', fontSize:16, borderRadius:8, backgroundColor:"#79BE23", color:"#fff", width:240, height:52, display:"flex", justifyContent:'center', alignItems:"center"}}>
            Посмотреть документ
        </a>
    </div>
  );
};

export default Agreement;

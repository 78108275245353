import { gql } from "@apollo/client"

export const FIND_UNIQUE_ORDER = gql`
	query(
		$where: OrderWhereUniqueInput!
	){
		findUniqueOrder(
			where: $where
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
export const FIND_FIRST_ORDER = gql`
	query(
		$where: OrderWhereInput
		$orderBy: [OrderOrderByWithRelationInput]
		$cursor: OrderWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OrderScalarFieldEnum]
	){
		findFirstOrder(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
export const FIND_MANY_ORDER = gql`
	query(
		$where: OrderWhereInput
		$orderBy: [OrderOrderByWithRelationInput]
		$cursor: OrderWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OrderScalarFieldEnum]
	){
		findManyOrderCount(
			where: $where
		)
		findManyOrder(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			city
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				balance
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
export const CREATE_ONE_ORDER = gql`
	mutation(
		$data: OrderCreateInput!
	){
		createOneOrder(
			data: $data
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
export const UPDATE_ONE_ORDER = gql`
	mutation(
		$data: OrderUpdateInput!
		$where: OrderWhereUniqueInput!
	){
		updateOneOrder(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
export const DELETE_ONE_ORDER = gql`
	mutation(
		$where: OrderWhereUniqueInput!
	){
		deleteOneOrder(
			where: $where
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
export const UPSERT_ONE_ORDER = gql`
	mutation(
		$where: OrderWhereUniqueInput!
		$create: OrderCreateInput!
		$update: OrderUpdateInput!
	){
		upsertOneOrder(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			from
			to
			startTime
			childrens
			comment
			price
			paymentMethod
			status
			cancelReason
			streaming
			streamId
			address
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			clientId
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				middlename
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			seenOrder{
				id
				createdAt
				updatedAt
				isViewed
				babySitter{
					id
					name
					surname
				}
				babySitterId
			}
			babySitterId
		}
	}
`
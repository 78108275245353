import { gql } from "@apollo/client"

export const FIND_UNIQUE_HELP_CATEGORY = gql`
	query(
		$where: HelpCategoryWhereUniqueInput!
	){
		findUniqueHelpCategory(
			where: $where
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
	}
`
export const FIND_FIRST_HELP_CATEGORY = gql`
	query(
		$where: HelpCategoryWhereInput
		$orderBy: [HelpCategoryOrderByWithRelationInput]
		$cursor: HelpCategoryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [HelpCategoryScalarFieldEnum]
	){
		findFirstHelpCategory(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
	}
`
export const FIND_MANY_HELP_CATEGORY = gql`
	query(
		$where: HelpCategoryWhereInput
		$orderBy: [HelpCategoryOrderByWithRelationInput]
		$cursor: HelpCategoryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [HelpCategoryScalarFieldEnum]
	){
		findManyHelpCategory(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
		findManyHelpCategoryCount(
			where: $where
		)
	}
`
export const CREATE_ONE_HELP_CATEGORY = gql`
	mutation(
		$data: HelpCategoryCreateInput!
	){
		createOneHelpCategory(
			data: $data
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
	}
`
export const UPDATE_ONE_HELP_CATEGORY = gql`
	mutation(
		$data: HelpCategoryUpdateInput!
		$where: HelpCategoryWhereUniqueInput!
	){
		updateOneHelpCategory(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
	}
`
export const DELETE_ONE_HELP_CATEGORY = gql`
	mutation(
		$where: HelpCategoryWhereUniqueInput!
	){
		deleteOneHelpCategory(
			where: $where
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
	}
`
export const UPSERT_ONE_HELP_CATEGORY = gql`
	mutation(
		$where: HelpCategoryWhereUniqueInput!
		$create: HelpCategoryCreateInput!
		$update: HelpCategoryUpdateInput!
	){
		upsertOneHelpCategory(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			weight
			title
			help{
				id
				createdAt
				updatedAt
				question
				answer
				categoryId
			}
			_count{
				help
			}
		}
	}
`
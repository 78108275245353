import { useMemo } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'
import { Form as AntForm, Input, Button, TimePicker, message } from 'antd'
import moment from 'moment'

import { LoadingView, Top } from '../components'
import { FIND_FIRST_SETTINGS, UPDATE_ONE_SETTINGS } from '../gqls'
import { timeFromDuration } from '../utils/hooks'

const requiredRule = {
    required: true,
    message: 'Обязательное поле'
}
const Form = styled(AntForm)`
    max-width: 600px;
`

const Settings = () => {

    const { data, loading } = useQuery(FIND_FIRST_SETTINGS, {
        fetchPolicy: 'network-only'
    })

    const [updateSettings, { loading: updateLoading }] = useMutation(UPDATE_ONE_SETTINGS, {
        onCompleted: () => {
            message.success("Настройки обновлены")
        },
        onError: e => { }
    })

    const settings = useMemo(() => data ? data.findFirstSettings : null, [data])

    const handleSubmitForm = ({ persent, persentOnLate, offerTime, orderDuration, orderDateOffset, orderBefreOffset, balanceForOrder, balanceForManyOrders, canTakeOrderInterval, waPhone, appVersion, bonusSaleUrl, emptyBanner, bannerScrollInterval }) => {
        // const seconds = moment(offerTime).get('seconds')
        const data = {
            persent: { set: parseFloat(persent / 100) },
            persentOnLate: { set: parseFloat(persentOnLate / 100) },
            offerTime: { set: (moment(offerTime).get('hours') * 60) + moment(offerTime).get('minutes') },
            orderDuration: { set: (moment(orderDuration).get('hours') * 60) + moment(orderDuration).get('minutes') },
            orderDateOffset: { set: parseInt(orderDateOffset * 60) },
            orderBefreOffset: { set: parseInt(orderBefreOffset) },
            balanceForOrder: { set: parseInt(balanceForOrder) },
            balanceForManyOrders: { set: parseInt(balanceForManyOrders) },
            canTakeOrderInterval: { set: (moment(canTakeOrderInterval).get('hours') * 60) + moment(canTakeOrderInterval).get('minutes') },
            waPhone: { set: waPhone},
            appVersion: { set: appVersion},
            bonusSaleUrl: { set: bonusSaleUrl},
            emptyBanner: { set: emptyBanner},
            bannerScrollInterval: { set: bannerScrollInterval*1000},
        }
        updateSettings({
            variables: {
                where: { id: settings.id },
                data
            }
        })
    }

    return (
        <>
            <Top title={"Настройки"} />
            {
                settings && (
                    <Form
                        onFinish={handleSubmitForm}
                        layout="vertical"
                        initialValues={{
                            persent: settings.persent * 100,
                            persentOnLate: settings.persentOnLate * 100,
                            offerTime: timeFromDuration(settings.offerTime),
                            orderDuration: timeFromDuration(settings.orderDuration),
                            orderDateOffset: Math.floor(settings.orderDateOffset / 60),
                            orderBefreOffset: settings.orderBefreOffset,
                            balanceForOrder: settings.balanceForOrder,
                            balanceForManyOrders: settings.balanceForManyOrders,
                            canTakeOrderInterval: timeFromDuration(settings.canTakeOrderInterval),
                            waPhone: settings?.waPhone,
                            appVersion: settings.appVersion,
                            bonusSaleUrl: settings.bonusSaleUrl,
                            emptyBanner: settings.emptyBanner,
                            bannerScrollInterval: settings.bannerScrollInterval/1000,
                        }}
                    >
                        <Form.Item
                            name={'persent'}
                            label='Процент от заказа'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Введите процент'
                                type={'number'}
                                max={100}
                                maxLength={3}
                                min={0}
                                minLength={1}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'persentOnLate'}
                            label='Процент от штрафа за опоздание'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Введите процент'
                                type={'number'}
                                max={100}
                                maxLength={3}
                                min={0}
                                minLength={1}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'orderDateOffset'}
                            label='Максимальное интервал даты выполнения заказа в часах'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Введите время'
                                type={'number'}
                                max={72}
                                maxLength={2}
                                min={1}
                                minLength={1}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'orderBefreOffset'}
                            label='Минимально время начала заказа в минутах'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Введите время'
                                type={'number'}
                                max={1440}
                                maxLength={4}
                                min={1}
                                minLength={1}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'offerTime'}
                            label='Длительность видимости предложения'
                            rules={[requiredRule]}
                        >
                            <TimePicker
                                placeholder='Выберите время'
                                showSecond={false}
                                format='HH:mm'
                            />
                        </Form.Item>
                        <Form.Item
                            name={'orderDuration'}
                            label='Длительность видимости заказа в ленте'
                            rules={[requiredRule]}
                        >
                            <TimePicker
                                placeholder='Выберите время'
                                showSecond={false}
                                format='HH:mm'
                            />
                        </Form.Item>
                        <Form.Item
                            name={'balanceForOrder'}
                            label='Минимальное количество денег на балансе для просмотра ленты'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Введите сколько должно быть на балансе'
                                type={'number'}
                                max={100000}
                                maxLength={3}
                                min={0}
                                minLength={1}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'balanceForManyOrders'}
                            label='Минимальное количество денег на балансе для возможности брать второй заказ'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Введите сколько должно быть на балансе'
                                type={'number'}
                                max={100000}
                                maxLength={3}
                                min={0}
                                minLength={1}
                            />
                        </Form.Item>
                        <Form.Item
                            name={'canTakeOrderInterval'}
                            label='Промежуток времени между заказами'
                            rules={[requiredRule]}
                        >
                            <TimePicker
                                placeholder='Выберите время'
                                showSecond={false}
                                format='HH:mm'
                            />
                        </Form.Item>
                        <Form.Item
                            name={'waPhone'}
                            label='Телефон ватцап'
                            rules={[requiredRule]}
                        >
                            <Input placeholder='+79999999999'></Input>
                        </Form.Item>
                        <Form.Item
                            name={'appVersion'}
                            label='Версия приложения'
                            rules={[requiredRule]}
                        >
                            <Input placeholder='1.0.0'></Input>
                        </Form.Item>
                        <Form.Item
                            name={'bonusSaleUrl'}
                            label='Ссылка на партнертские бонусы'
                            rules={[requiredRule]}
                        >
                            <Input placeholder='podprismotrom-ykt.ru'></Input>
                        </Form.Item>
                        <Form.Item
                            name={'emptyBanner'}
                            label='Ссылка на изображение пустого баннера'
                            rules={[requiredRule]}
                        >
                            <Input placeholder='https://url/file.png'></Input>
                        </Form.Item>
                        {/* <Form.Item
                            name={'bannerScrollInterval'}
                            label='Скорость прокрутки'
                            rules={[requiredRule]}
                        >
                            <Input placeholder='podprismotrom-ykt.ru'></Input>
                        </Form.Item> */}
                        <Form.Item
                            name={'bannerScrollInterval'}
                            label='Скорость прокрутки баннера'
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder='Скорость в секундах'
                                type={'number'}
                                max={1000}
                                maxLength={3}
                                min={0}
                                minLength={1}
                            />
                        </Form.Item>
                        <Button loading={updateLoading} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form>
                )
            }
            <LoadingView loading={loading} />
        </>
    )
}

export default Settings
import { useEffect } from 'react'
import { Button, Form, message, Input, Select } from 'antd'
import {Top} from '../../components/Top'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../utils/hooks'
import { useParams, useNavigate} from 'react-router-dom'
import { DELETE_ONE_FAQ, FIND_UNIQUE_FAQ, UPDATE_ONE_FAQ } from '../../gqls'
import { DELETE_ONE_CANCEL_REASONS, FIND_UNIQUE_CANCEL_REASONS, UPDATE_ONE_CANCEL_REASONS } from '../../gqls/cancelreasons'
const { TextArea } = Input

const UpdateCancelReasons = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { user, loading: loadingMe } = useUser()

  useEffect(() => {
    if (!user?.role?.canHelp) navigate(-1)
  })

  const { data, loading: loadingRole } = useQuery(FIND_UNIQUE_CANCEL_REASONS, {
    fetchPolicy: 'network-only',
    variables: { where: { id } },
  })

  const onCompleted = () => navigate(-1)
  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [updateOneCancelReason, { loading }] = useMutation(UPDATE_ONE_CANCEL_REASONS, { onCompleted, onError })

  const [deleteOneCancelReason, { loading: loadingDel }] = useMutation(DELETE_ONE_CANCEL_REASONS, { onCompleted, onError })

  const handleCreate = (v) => {
    const obj = {}
    for (let key of Object.keys(v)) {
      obj[key] = { set: v[key] }
    }
    updateOneCancelReason({ variables: { where: { id }, data: obj } })
  }

  const deleteAdminRole = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneCancelReason({ variables: { where: { id } } })
  }

  if (loadingMe || loadingRole) return 'Загрузка...'
  if (!user?.role?.canHelp) return 'Ошибка'
  const faq = data?.findUniqueCancelReasons

  return (
    <>
      <Top title='Редактировать причину отмены' />
      <div style={{ maxWidth: 700 }}>
        <Form onFinish={handleCreate} layout='vertical' initialValues={faq}>
          <Form.Item label="Причина отмены" rules={[{ required: true }]} name='title'>
            <Input ></Input>
          </Form.Item>

          <Form.Item name='type' label='Тип (Где?)' required>
            <Select placeholder='Выберите тип'>
              {['PARENT_EMPTY_ORDER','PARENT_TAKED_ORDER','SITTER_TAKED_ORDER'].map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Button loading={loading} htmlType='submit' type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel} onClick={deleteAdminRole} type='primary'>
            Удалить
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdateCancelReasons;

import { gql } from "@apollo/client"

export const FIND_UNIQUE_SETTINGS = gql`
	query(
		$where: SettingsWhereUniqueInput!
	){
		findUniqueSettings(
			where: $where
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
export const FIND_FIRST_SETTINGS = gql`
	query(
		$where: SettingsWhereInput
		$orderBy: [SettingsOrderByWithRelationInput]
		$cursor: SettingsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [SettingsScalarFieldEnum]
	){
		findFirstSettings(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
export const FIND_MANY_SETTINGS = gql`
	query(
		$where: SettingsWhereInput
		$orderBy: [SettingsOrderByWithRelationInput]
		$cursor: SettingsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [SettingsScalarFieldEnum]
	){
		findManySettings(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
export const CREATE_ONE_SETTINGS = gql`
	mutation(
		$data: SettingsCreateInput!
	){
		createOneSettings(
			data: $data
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
export const UPDATE_ONE_SETTINGS = gql`
	mutation(
		$data: SettingsUpdateInput!
		$where: SettingsWhereUniqueInput!
	){
		updateOneSettings(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
export const DELETE_ONE_SETTINGS = gql`
	mutation(
		$where: SettingsWhereUniqueInput!
	){
		deleteOneSettings(
			where: $where
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
export const UPSERT_ONE_SETTINGS = gql`
	mutation(
		$where: SettingsWhereUniqueInput!
		$create: SettingsCreateInput!
		$update: SettingsUpdateInput!
	){
		upsertOneSettings(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			persent
			persentOnLate
			offerTime
			orderDuration
			orderDateOffset
			orderBefreOffset
			balanceForOrder
			balanceForManyOrders
			canTakeOrderInterval
			waPhone
			appVersion
			bonusSaleUrl
			emptyBanner
			bannerScrollInterval
		}
	}
`
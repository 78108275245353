import { Button, Table, message, Popconfirm } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_CANCEL_REASONS } from '../../gqls/cancelreasons'
import { DELETE_ONE_ADS_BANNER, FIND_MANY_ADS_BANNER } from '../../gqls/adsbanner'
import { QuestionCircleOutlined } from '@ant-design/icons'
const limit = 50

const AdsBanner = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const { id } = useParams()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canHelp) navigate(-1)
  })

  const { data, loading, refetch } = useQuery(FIND_MANY_ADS_BANNER, {
    fetchPolicy: 'network-only',
    variables: { where:{cityId:{equals:id}}, take: limit, skip },
  })

  const [deleteOneAdsBanner] = useMutation(DELETE_ONE_ADS_BANNER)

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyAdsBanner : []
  const total = data ? data?.findManyAdsBanner.length : 0
  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Тип (Где?)',
      dataIndex: 'type',
      key: 'type',
      filters: [
        {
          text: 'Страница заказа родителя',
          value: 'PARENT_ORDER',
        },
        {
          text: 'Страница бонусов родителя',
          value: 'PARENT_BONUS',
        },
        {
          text: 'Страница бонусов няни',
          value: 'SITTER_BONUS',
        },
        {
          text: 'Страница поиска заказов няни',
          value: 'SITTER_SEARCH',
        },
      ],
      filterMode: 'tree',
      onFilter: (value, record) => record.type.startsWith(value),
    },
  ]

  if (user?.role?.canHelp) {
    // columns.push({
    //   title: 'Действие',
    //   key: 'operation',
    //   align: 'center',
    //   render: (record) => <Button type="danger" to={`/ads-banner/update/${record.id}`}>Удалить</Button>,
    // })
    columns.push({
      title: 'Изображение',
      key: 'url',
      align: 'center',
      render: (record) => record?.image ? <a target="_blank" href={`https://podprismotrom-ykt.ru/uploads/${record?.image}`}>Перейти</a> : 'Нету',
    })
    columns.push({
      title: 'Ссылка',
      key: 'url',
      align: 'center',
      render: (record) => record?.url ? <a target="_blank" href={`${record?.url}`}>Перейти</a> : 'Нету',
    })
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Popconfirm title={`Вы действительно хотите удалить баннер ${record?.title}?`} description={`Вы действительно хотите удалить баннер ${record?.title}?`} onConfirm={async()=>{await deleteOneAdsBanner({variables:{where:{id:record?.id}}}); message.success('Вы удалили баннер'); refetch()}} onCancel={()=>{}} icon={<QuestionCircleOutlined style={{ color: 'red' }} />} okText="Да" cancelText="Нет"><Button danger>Удалить</Button></Popconfirm>,
    })
  }

  return (
    <>
      <Top title='Баннеры' action={user?.role?.canHelp && <Link to={`/ads-banner/create/${id}`}>Добавить</Link>} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default AdsBanner

import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_CANCEL_REASONS } from '../../gqls/cancelreasons'
const limit = 50

const CancelReasons = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canHelp) navigate(-1)
  })

  const { data, loading } = useQuery(FIND_MANY_CANCEL_REASONS, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyCancelReasons : []
  const total = data ? data?.findManyCancelReasons.length : 0
  const columns = [
    {
      title: 'Причина отмены',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Тип (Где?)',
      dataIndex: 'type',
      key: 'type',
      filters: [
        {
          text: 'Отмена пустого заказа',
          value: 'PARENT_EMPTY_ORDER',
        },
        {
          text: 'Отмена родителем принятого заказа',
          value: 'PARENT_TAKED_ORDER',
        },
        {
          text: 'Отмена няней принятого заказа',
          value: 'SITTER_TAKED_ORDER',
        },
      ],
      filterMode: 'tree',
      onFilter: (value, record) => record.type.startsWith(value),
    },
  ]

  if (user?.role?.canHelp) {
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/cancel-reasons/update/${record.id}`}>Изменить</Link>,
    })
  }

  return (
    <>
      <Top title='Причины отмены' action={user?.role?.canHelp && <Link to={`/cancel-reasons/create`}>Добавить</Link>} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default CancelReasons

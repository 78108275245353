import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
    Form as AntForm,
    Input,
    Button,
    message,
    Table,
    Modal,
    Popconfirm
} from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'

import {
    Top,
    LoadingView
} from '../../components'
import { CREATE_ONE_HELP, DELETE_ONE_HELP, FIND_UNIQUE_HELP_CATEGORY, UPDATE_ONE_HELP, UPDATE_ONE_HELP_CATEGORY } from '../../gqls'
import { useUser } from '../../utils/hooks'

const Form = styled(AntForm)`
    max-width: 600px;
`
const Question = styled.div`
    color: #1890ff;
    cursor: pointer;
`
const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddHelpCategory = () => {
    // const { user } = useUser()
    const [form] = Form.useForm()
    // const navigate = useNavigate()
    const { id } = useParams()
    const addModal = useRef()

    const [deleteHelp, { loading: deleteLoading }] = useMutation(DELETE_ONE_HELP, {
        onCompleted: () => {
            message.success("Вопрос удален")
            refetch()
        },
        onError: e => { }
    })

    const { data, loading, refetch } = useQuery(FIND_UNIQUE_HELP_CATEGORY, {
        variables: {
            where: { id }
        },
        onCompleted: ({ findUniqueHelpCategory }) => {
            form.setFieldsValue({
                title: findUniqueHelpCategory.title
            })
        }
    })

    const [updateCategory, { loading: updateLoading }] = useMutation(UPDATE_ONE_HELP_CATEGORY, {
        onCompleted: () => {
            message.success("Категория обновлено")
        },
        onError: e => { }
    })

    const handleSubmit = ({ title }) => {
        updateCategory({
            variables: {
                where: { id },
                data: {
                    title: { set: title }
                }
            }
        })
    }

    const category = useMemo(() => data ? data.findUniqueHelpCategory : [], [data])

    return (
        <>
            <Top title={"Редактировани категории вопроса"} />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    payerStatus: 'default'
                }}
                style={{ marginBottom: 24 }}
            >
                <Form.Item
                    name={"title"}
                    rules={[rules.required]}
                    label="Заголовок"
                >
                    <Input placeholder='Введите заголовок' />
                </Form.Item>
                <Button loading={updateLoading} type="primary" htmlType="submit">
                    Сохранить
                </Button>
            </Form>
            <Top
                title={"Вопросы"}
                action={
                    <Button onClick={() => addModal.current.openModal()}>
                        + Добавить
                    </Button>
                }
            />
            <Table
                rowKey={(obj) => obj.id}
                loading={loading || deleteLoading}
                dataSource={category.help}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                columns={[
                    {
                        title: '№',
                        render: (id, obj, index) => index + 1,
                        width: 100
                    },
                    {
                        title: 'Вопрос',
                        dataIndex: 'question',
                        key: 'question',
                        render: (question, obj) => <Question onClick={() => addModal.current.openModal(obj)}>{question}</Question>
                    },
                    {
                        title: 'Вопрос',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => (
                            <Popconfirm
                                title='Удалить вопрос?'
                                onConfirm={() => {
                                    deleteHelp({
                                        variables: {
                                            where: { id }
                                        }
                                    })
                                }}
                            >
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                            </Popconfirm>
                        ),
                        width: 50
                    }
                ]}
            />
            <LoadingView loading={loading} />
            <AddModal
                ref={addModal}
                helpCategory={category}
                onCompleted={refetch}
            />
        </>
    )
}

const AddModal = forwardRef(({ helpCategory, onCompleted = () => { } }, ref) => {
    const [isOpen, setIsOpen] = useState()
    const [selectedHelp, setSelectedHelp] = useState()
    const [form] = Form.useForm()

    const [createHelp, { loading }] = useMutation(CREATE_ONE_HELP, {
        onCompleted: () => {
            message.success("Вопрос добавлен")
            onCompleted()
            closeModal()
        },
        onError: e => { }
    })

    const [updateHelp, { loading: updateLoadin }] = useMutation(UPDATE_ONE_HELP, {
        onCompleted: () => {
            message.success("Вопрос обновлен")
            onCompleted()
            closeModal()
        },
        onError: e => { }
    })

    const closeModal = () => {
        setIsOpen(false)
        form.resetFields()
        setSelectedHelp(undefined)
    }

    const openModal = (help) => {
        if (help) {
            setSelectedHelp(help)
            form.setFieldsValue({
                question: help.question,
                answer: help.answer
            })
        }
        setIsOpen(true)
    }

    const onSubmit = ({ answer, question }) => {
        if (selectedHelp) {
            updateHelp({
                variables: {
                    where: { id: selectedHelp.id },
                    data: {
                        answer: { set: answer },
                        question: { set: question },
                    }
                }
            })
        } else {
            createHelp({
                variables: {
                    data: {
                        answer,
                        question,
                        category: {
                            connect: { id: helpCategory.id }
                        }
                    }
                }
            })
        }
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }), [openModal, closeModal])

    return (
        <Modal
            visible={isOpen}
            title='Добавление вопроса'
            onCancel={closeModal}
            onOk={form.submit}
            okButtonProps={{
                loading: loading || updateLoadin
            }}
            cancelButtonProps={{
                loading: loading || updateLoadin
            }}
        >
            <Form
                onFinish={onSubmit}
                layout='vertical'
                form={form}
            >
                <Form.Item
                    name='question'
                    rules={[rules.required]}
                    label="Вопрос"
                >
                    <Input placeholder='Введите вопрос' />
                </Form.Item>
                <Form.Item
                    name='answer'
                    rules={[rules.required]}
                    label="Ответ"
                >
                    <Input.TextArea placeholder='Введите вопрос' style={{ minHeight: 200 }} />
                </Form.Item>
            </Form>
        </Modal>
    )
})

export default AddHelpCategory
import styled from "styled-components";
import { Form as AntForm, Input, Button, message, Select } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { LoadingView, Top } from "../../components";
import { UPDATE_ONE_USER, FIND_UNIQUE_USER } from "../../gqls";

const { Option } = Select

const Form = styled(AntForm)`
  max-width: 600px;
`;
const rules = {
  required: {
    required: true,
    message: "Обязательное поле",
  },
};

const EditClient = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(FIND_UNIQUE_USER, {
    variables: {
      where: { id },
    },
    onCompleted: ({ findUniqueUser: { name, rating, phone, email, league } }) => {
      form.setFieldsValue({
        name,
        phone,
        email,
        rating: parseFloat(rating).toFixed(2),
        league
      });
    },
  });

  console.log(data, "DATA");

  const [updateClient, { loading: updateLoading }] = useMutation(
    UPDATE_ONE_USER,
    {
      onCompleted: () => {
        navigate("/client");
        message.success("Клиент обновлен");
      },
      onError: (e) => {},
    }
  );

  const handleSubmit = ({ rating, name, phone, email, league }) => {
    updateClient({
      variables: {
        where: { id },
        data: {
          rating: { set: parseFloat(rating) },
          name: { set: name },
          phone: { set: phone },
          email: { set: email },
          league: { set: league },
        },
      },
    });
  };

  return (
    <>
      <Top title={"Редактирование пользователя"} />
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name={"name"} rules={[rules.required]} label="Имя">
          <Input placeholder="Введите имя" />
        </Form.Item>

        <Form.Item name={"phone"} rules={[rules.required]} label="Телефон">
          <Input placeholder="Введите телефон" />
        </Form.Item>

        <Form.Item name={"email"} label="Почта">
          <Input placeholder="Введите почту" />
        </Form.Item>

        <Form.Item name={"rating"} rules={[rules.required]} label="Рейтинг">
          <Input
            placeholder="Введите новый рейтинг"
            type="number"
            step={0.01}
          />
        </Form.Item>

        <Form.Item name={"league"} label="Ранг">
            <Select placeholder='Выберите роль'>
              {['NONE', 'BRONZE', 'SILVER', 'GOLD'].map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
        </Form.Item>

        <Button loading={updateLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form>
      <LoadingView loading={loading} />
    </>
  );
};

export default EditClient;

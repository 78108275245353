import { gql } from "@apollo/client"

export const DELETE_MANY_ADMIN = gql`
	mutation(
		$where: AdminWhereInput
	){
		deleteManyAdmin(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN = gql`
	mutation(
		$data: AdminUpdateManyMutationInput!
		$where: AdminWhereInput
	){
		updateManyAdmin(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_USER = gql`
	mutation(
		$where: UserWhereInput
	){
		deleteManyUser(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_USER = gql`
	mutation(
		$data: UserUpdateManyMutationInput!
		$where: UserWhereInput
	){
		updateManyUser(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_BABY_SITTER = gql`
	mutation(
		$where: BabySitterWhereInput
	){
		deleteManyBabySitter(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_BABY_SITTER = gql`
	mutation(
		$data: BabySitterUpdateManyMutationInput!
		$where: BabySitterWhereInput
	){
		updateManyBabySitter(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_DOCUMENT = gql`
	mutation(
		$where: DocumentWhereInput
	){
		deleteManyDocument(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_DOCUMENT = gql`
	mutation(
		$data: DocumentUpdateManyMutationInput!
		$where: DocumentWhereInput
	){
		updateManyDocument(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_ORDER = gql`
	mutation(
		$where: OrderWhereInput
	){
		deleteManyOrder(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ORDER = gql`
	mutation(
		$data: OrderUpdateManyMutationInput!
		$where: OrderWhereInput
	){
		updateManyOrder(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_OFFER = gql`
	mutation(
		$where: OfferWhereInput
	){
		deleteManyOffer(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_OFFER = gql`
	mutation(
		$data: OfferUpdateManyMutationInput!
		$where: OfferWhereInput
	){
		updateManyOffer(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_REVIEW = gql`
	mutation(
		$where: ReviewWhereInput
	){
		deleteManyReview(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_REVIEW = gql`
	mutation(
		$data: ReviewUpdateManyMutationInput!
		$where: ReviewWhereInput
	){
		updateManyReview(
			data: $data
			where: $where
		){
			count
		}
	}
`
import { useEffect, useState } from "react";
import { Button, Form, message, Input, Select } from "antd";
import { Top } from "../../components/Top";
import { useMutation } from "@apollo/client";
import { useUser } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { CREATE_ONE_CITY } from "../../gqls/city";

import MapPicker from "react-google-map-picker";

const { Option } = Select;
const requiredRule = { required: true, message: "Обязательное поле" };
// const emailRule = { type: "email", message: "Введите правильный Email" };

const DefaultLocation = { lat: 62.036435723057444, lng: 129.7460828395273 };
const DefaultZoom = 4;

const CreateOneCity = () => {
  const { user, loading: loadingMe } = useUser();
  const navigate = useNavigate();

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  useEffect(() => {
    if (!user?.role?.canChangeRole) navigate(-1);
  });

  // const { data, loading: loadingRole } = useQuery(FIND_MANY_ADMIN_ROLE, {
  //   fetchPolicy: 'network-only',
  // })

  const [createOneCity, { loading }] = useMutation(CREATE_ONE_CITY, {
    onCompleted: () => navigate("/city"),
    onError: (err) => {
      console.error(err);
      message.error("Не удалось выполнить запрос");
    },
  });

  const handleCreate = (v) => {
    createOneCity({ variables: { data: { ...v, location:{lalitude:location?.lat, longitude:location.lng} } } });
  };

  if (loadingMe) return "Загрузка...";
  if (!user?.role?.canSettings) return "Ошибка";
  // const role = data.findManyAdminRole
  return (
    <>
      <Top title="Добавить город" />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout="vertical">
          <Form.Item name="title" label="Название города" required>
            <Input />
          </Form.Item>
          {/* <Form.Item name='role' label='Роль' required>
            <Select placeholder='Выберите роль'>
              {role.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item name="description" label="Описание" rules={[requiredRule]}>
            <Input />
          </Form.Item>
          {/* <Form.Item name='password' label='Пароль' required>
            <Input.Password />
          </Form.Item>
          <Form.Item name='repassword' label='Повторите пароль' required>
            <Input.Password />
          </Form.Item> */}

  <label>Широна (Latitute):</label><Input type='text' value={location.lat} disabled/>
  <label>Долгота (Longitute):</label><Input type='text' value={location.lng} disabled/>
  <div style={{height:24}}></div>

          <MapPicker
            defaultLocation={defaultLocation}
            zoom={zoom}
            mapTypeId="roadmap"
            style={{ height: "700px" }}
            onChangeLocation={handleChangeLocation}
            onChangeZoom={handleChangeZoom}
            apiKey="AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8"
          />
  <div style={{height:24}}></div>
          <Button loading={loading} htmlType="submit" type="primary">
            Добавить
          </Button>
        </Form>
      </div>
    </>
  );
};

export default CreateOneCity;

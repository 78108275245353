import { gql } from "@apollo/client"

export const FIND_UNIQUE_OFFER = gql`
	query(
		$where: OfferWhereUniqueInput!
	){
		findUniqueOffer(
			where: $where
		){
			id
			createdAt
			updatedAt
			order{
				id
				createdAt
				updatedAt
				dateTime
				startTime
				childrens
				comment
				offeredPrice
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				executor{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
export const FIND_FIRST_OFFER = gql`
	query(
		$where: OfferWhereInput
		$orderBy: [OfferOrderByWithRelationInput]
		$cursor: OfferWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OfferScalarFieldEnum]
	){
		findFirstOffer(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			order{
				id
				createdAt
				updatedAt
				dateTime
				startTime
				childrens
				comment
				offeredPrice
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				executor{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
export const FIND_MANY_OFFER = gql`
	query(
		$where: OfferWhereInput
		$orderBy: [OfferOrderByWithRelationInput]
		$cursor: OfferWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OfferScalarFieldEnum]
	){
		findManyOffer(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			price
			order{
				id
				createdAt
				updatedAt
				childrens
				comment
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
export const CREATE_ONE_OFFER = gql`
	mutation(
		$data: OfferCreateInput!
	){
		createOneOffer(
			data: $data
		){
			id
			createdAt
			updatedAt
			order{
				id
				createdAt
				updatedAt
				dateTime
				startTime
				childrens
				comment
				offeredPrice
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				executor{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
export const UPDATE_ONE_OFFER = gql`
	mutation(
		$data: OfferUpdateInput!
		$where: OfferWhereUniqueInput!
	){
		updateOneOffer(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			order{
				id
				createdAt
				updatedAt
				startTime
				childrens
				comment
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
export const DELETE_ONE_OFFER = gql`
	mutation(
		$where: OfferWhereUniqueInput!
	){
		deleteOneOffer(
			where: $where
		){
			id
			createdAt
			updatedAt
			order{
				id
				createdAt
				updatedAt
				dateTime
				startTime
				childrens
				comment
				offeredPrice
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				executor{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
export const UPSERT_ONE_OFFER = gql`
	mutation(
		$where: OfferWhereUniqueInput!
		$create: OfferCreateInput!
		$update: OfferUpdateInput!
	){
		upsertOneOffer(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			order{
				id
				createdAt
				updatedAt
				dateTime
				startTime
				childrens
				comment
				offeredPrice
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				clientId
				executor{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			status
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				documents{
					id
					createdAt
					updatedAt
					block
					delete
					babySitterId
					image
					date
					type
				}
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
				}
				userId
			}
			isViewed
			comment
			babySitterId
			senderId
		}
	}
`
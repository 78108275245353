import { useEffect, useState } from 'react'
import { Button, Form, message, Input, Select } from 'antd'
import {Top} from '../../components/Top'
import { useMutation } from '@apollo/client'
import { useUser } from '../../utils/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { CREATE_ONE_CANCEL_REASONS } from '../../gqls/cancelreasons'
import { UPLOAD_FILE } from '../../gqls/string'
import { CREATE_ONE_ADS_BANNER } from '../../gqls/adsbanner'

const { Option } = Select
const requiredRule = { required: true }

const CreateAdsBanner = () => {
  const { user, loading: loadingMe } = useUser()
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    if (!user?.role?.canHelp) navigate(-1)
  })

  const [uploadFile, setUploadFile] = useState(null)

  const [createOneAdsBanner, { loading }] = useMutation(CREATE_ONE_ADS_BANNER, {
    onCompleted: () => navigate(-1),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const [upload] = useMutation(UPLOAD_FILE)

  const handleImageUpload = async (event) => {
    const { validity, files: [file], } = event.target;
    if (validity.valid) {
        upload({
          variables: { file: file },
          onCompleted: (async (data)=>{
            console.log(data.uploadFile,'DATA')
            setUploadFile(data.uploadFile)
          }),
          // (data) => setRouteImage('https://yaktors.ru/image/'+data.singleUpload),
          onError: (er) => console.log(er),
        })
      }
  };

  const handleCreate = (v) => {
    v.city = {connect:{id:id}}
    v.image = uploadFile
    createOneAdsBanner({ variables: { data: v } })
  }


  if (loadingMe) return 'Загрузка...'
  if (!user?.role?.canHelp) return 'Ошибка'
  return (
    <>
      <Top title='Добавить причину отмены' />
      <div style={{ maxWidth: 700 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          {/* <Form.Item name='title' label='Название' rules={[requiredRule]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' label='Описание'>
            <TextArea rows={4} />
          </Form.Item>
          <h3>Права:</h3> */}
          <Form.Item label="Заголовок" rules={[requiredRule]} name='title'>
            <Input ></Input>
          </Form.Item>
          <Form.Item label="Описание" name='description'>
            <Input ></Input>
          </Form.Item>
          <Form.Item label="Ссылка" name='url'>
            <Input ></Input>
          </Form.Item>



          <Form.Item name='type' label='Тип (Где?)' required>
            <Select placeholder='Выберите тип'>
              {['PARENT_BONUS', 'PARENT_ORDER', 'SITTER_BONUS','SITTER_SEARCH'].map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="image" label="Загрузить фото" rules={[{ required: true, message: 'Выберите файл' }]}>
            <Input onChange={handleImageUpload} accept='.png, .jpg, .jpeg' type="file" />
          </Form.Item>

          <Button disabled={uploadFile ? false : true}  loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateAdsBanner

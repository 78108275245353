import { useMemo } from "react"
import styled from "styled-components"
import {
    Button,
    Table,
    Form as AntForm,
    Select,
    Input,
    message,
    Tag,
    Popover,
    Popconfirm
} from 'antd'
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Link, useParams } from "react-router-dom"
// import IMask from 'imask'

import { Top } from '../../components'
import { FIND_MANY_OFFER, UPDATE_ONE_OFFER } from "../../gqls"
import { useRouteQuery, useNavigateSearch } from "../../utils/hooks"
// import { ADMIN_TYPES } from "../../utils/const"

const limit = 20

const Offer = () => {
    const { id } = useParams();
    const query = useRouteQuery()
    const { page = 1, search = "", status = undefined } = query

    const variables = useMemo(() => {
        const variables = { where: {
            order:{
                id:{
                    equals:id
                }
            }
        } }
        return variables
    }, [search])

    const { data, loading } = useQuery(FIND_MANY_OFFER, {
        variables: {
            ...variables,
            take: limit,
            skip: (page - 1) * limit,
        },
        fetchPolicy: 'network-only'
    })

    const [updateOffer, { loading: updateLoading }] = useMutation(UPDATE_ONE_OFFER, {
        onCompleted: () => {
            message.success("Предложение отменено")
        }
    })

    const renderStatus = (status, offer) => {
        console.log(offer,'OFFER')
        let text = 'Новый'
        let color = 'yellow'
        if (status === 'rejected') {
            text = 'Отменен'
            color = 'red'
        }
        if (status === 'confirmed') {
            text = 'Подтвержден'
            color = 'green'
        }
        // if (status === 'new') {
        //     text = 'Новый'
        //     color = 'yellow'
        // }
        if (status === 'new') {
            return (
                <Popover
                    content={
                        <Popconfirm
                            title='Отменить предложение?'
                            onConfirm={() => {
                                updateOffer({
                                    variables: {
                                        where: { id: offer.id },
                                        data: {
                                            status: { set: "rejected" }
                                        }
                                    }
                                })
                            }}
                        >
                            <Button danger>
                                Отменить предложение
                            </Button>
                        </Popconfirm>
                    }
                >
                    <Tag style={{ cursor: "pointer" }} color={color}>{text}</Tag>
                </Popover>
            )
        }
        return <Tag color={color}>{text}</Tag>
    }

    const offers = useMemo(() => data ? data.findManyOffer : [], [data])
    const offersCount = useMemo(() => data ? data.findManyOfferCount : 0, [data])

    return (
        <>
            <Top
                title={`Оффера к заказу id: ${id}`}
            />
            <Table
                loading={loading || updateLoading}
                rowKey={(obj) => obj.id}
                dataSource={offers}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: parseInt(page),
                    total: offersCount,
                    pageSize: limit
                }}
                columns={[
                    {
                        title: 'Няня',
                        dataIndex: 'babySitter',
                        key: 'babySitter',
                        render: (babySitter, obj) => babySitter ? (
                            <Link to={`/baby-sitter/${babySitter.id}`}>
                                {babySitter.name} {babySitter.surname} {babySitter.middlename}
                            </Link>
                        ) : 'Исполнитель не найден'
                    },
                    {
                        title: 'Цена',
                        dataIndex: 'price',
                        key: 'price',
                        render: (price) => (
                            <span>{price} 🪙</span>
                        )
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: renderStatus
                    },
                    {
                        title: 'Просмотрено ?',
                        dataIndex: 'isViewed',
                        key: 'isViewed',
                        render: (isViewed, obj) => (
                            <span>{isViewed ? "Да" : "Нет"}</span>
                        )
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Коммент няни',
                        dataIndex: 'comment',
                        key: 'comment',
                        render: (comment, obj) => (
                            <span>{comment ? comment : 'Нету'}</span>
                        )
                    },
                ]}
            />
        </>
    )
}

export default Offer
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Peer } from 'peerjs'
import Webcam from 'react-webcam'
import { BsCameraVideoOff, BsPauseFill } from 'react-icons/bs'
import { IoMdReverseCamera, IoMdShare } from 'react-icons/io'
import { AiFillAudio, AiOutlineAudioMuted } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { useSubscription, useMutation } from '@apollo/client'
import { useRouteQuery } from '../utils/hooks'
import { PUB, SUB, UPDATE_ONE_ORDER } from '../gqls'

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    background-color: #2B2D35;
`

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: #2B2D35;
`

const Audio = styled.video`
    height: 0;
    width: 0;
    background-color: transparent;
    position: absolute;
    opacity: 0;
`

const PosterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #2B2D35;
    position: absolute;
`

const StyledWebcam = styled(Webcam)`
    height: 100%;
    width: 100%;
    background-color: black;
    object-fit: cover;
`

const Footer = styled.div`
    position: absolute; 
    bottom: 39px; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MenuButton = styled.div`
    height: 48px;
    width: 48px;
    background-color: white;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const OnStream = styled.div`
    height: 34px;
    width: 34px;
    border-radius: 17px;
    background-color: #E9303C;
    backdrop-filter: blur(13.5px);
`

const TranslateButton = styled.div`
    height: 58px;
    width: 58px;
    background-color: rgba(212, 212, 212, 0.2);
    backdrop-filter: blur(13.5px);
    border-radius: 28px;
    margin-right: 36px;
    margin-left: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Poster = () => (
    <PosterContainer>
        <BsCameraVideoOff style={{ color: '#fff', fontSize: 100 }} />
    </PosterContainer>
)

const Room = () => {
    const { id } = useParams()
    const { role = 'slave' } = useRouteQuery()

    const peer = useRef(new Peer()).current
    const videoRef = useRef(null)
    const audioRef = useRef(null)
    const stream = useRef(null)
    const connection = useRef(null)

    const [userId, setUserId] = useState(null)
    const [facingMode, setFacingMode] = useState('environment')
    const [translate, setTranslate] = useState(false)
    const [audio, setAudio] = useState(false)

    useSubscription(SUB, {
        variables: {
            observer: `${id}-${role}`
        },
        onSubscriptionData: async ({ subscriptionData }) => {
            if (subscriptionData && subscriptionData.data && subscriptionData.data.sub) {
                const { query, uuid } = subscriptionData.data.sub
                if (query === 'uuid' && uuid) await callOwner(uuid)
                if (query === 'join' && userId && translate) {
                    await pub({
                        variables: {
                            observer: `${id}-slave`,
                            data: {
                                query: 'uuid',
                                uuid: userId
                            }
                        }
                    })
                }
                if (query === 'close' && translate) setTranslate(false)
                if (query === 'reconnect') {
                    peer.disconnect()
                    peer.reconnect()
                }
                if (query === 'audio') {
                    setAudio(prev => !prev)
                }
            }
        },
    })

    const [updateOrder] = useMutation(UPDATE_ONE_ORDER, {
        onError: error => {
            console.log(error)
        }
    })

    const [pub] = useMutation(PUB, {
        onError: error => {
            console.log(error)
        }
    })

    const callOwner = async (data) => {
        stream.current.getVideoTracks().forEach((track) => track.stop())
        const call = peer.call(data, stream.current)
        console.log(call,'CALL CHECK')
        call.on('stream', data => {
            if ('srcObject' in videoRef.current) {
                console.log(data,' DATA STREAM CHECAK')
                videoRef.current.srcObject = data
            } else {
                console.log(data,' DATA STREAM CHECAK')
                videoRef.current.src = window.URL.createObjectURL(data)
            }
            setTranslate(true)
        })
    }

    useEffect(() => {
        peer.on('open', async uuid => {
            setUserId(uuid)
            if (role !== 'owner') {
                stream.current = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                await pub({
                    variables: {
                        observer: `${id}-owner`,
                        data: {
                            query: 'join'
                        }
                    }
                })
            }
        })
        peer.on('call', call => {
            if (role === 'owner') {
                connection.current = call
                call.answer(stream.current)
                call.on('stream', data => {
                    if ('srcObject' in audioRef.current) {
                        audioRef.current.srcObject = data
                    } else {
                        audioRef.current.src = window.URL.createObjectURL(data)
                    }
                })
            }
        })
    }, [])

    const flipCamera = async () => {
        setFacingMode(prev => prev === 'user' ? 'environment' : 'user')
        if (translate) {
            await pub({
                variables: {
                    observer: `${id}-slave`,
                    data: {
                        query: 'close'
                    }
                }
            })
            await pub({
                variables: {
                    observer: `${id}-slave`,
                    data: {
                        query: 'uuid',
                        uuid: userId
                    }
                }
            })
        }
    }

    const onTranslate = async () => {
        if (!translate) {
            setTranslate(true)
            await pub({
                variables: {
                    observer: `${id}-slave`,
                    data: {
                        query: 'uuid',
                        uuid: userId
                    }
                }
            })
        }
        if (translate) {
            setTranslate(false)
            await pub({
                variables: {
                    observer: `${id}-slave`,
                    data: {
                        query: 'close'
                    }
                }
            })
            await updateOrder({
                variables: {
                    where: { id },
                    data: {
                        streaming: { set: true }
                    }
                }
            })
            connection.current.close()
        }
    }

    const shareTranslate = () => {
        // https://podprismotrom-ykt.ru/room/cljcgeg0p5549k7kqb4e1zwb0?role=owner
        // window.ReactNativeWebView.postMessage('onShare')
        let text = `Подключитесь к видео-няне по ссылке: https://podprismotrom-ykt.ru/room/cljcgeg0p5549k7kqb4e1zwb0?role=slave`
        const url = `whatsapp://send?text=${encodeURIComponent(text)}`;
        window.open(url, '_blank');
        // http://localhost:3000/room/cljcgeg0p5549k7kqb4e1zwb0?role=owner
    }

    const recon = () => {
        if(translate){
            setInterval(async ()=>{
                await pub({
                    variables: {
                        observer: `${id}-slave`,
                        data: {
                            query: 'close'
                        }
                    }
                })
            }, 10000)
        }
    }

    useEffect(()=>{
        recon()
    },[])

    const onAudio = async () => {
        setAudio(prev => !prev)
        await pub({
            variables: {
                observer: `${id}-owner`,
                data: {
                    query: 'audio'
                }
            }
        })
    }

    console.log(videoRef,'videoRef')
    console.log(stream,'STREAM')

    return (
        <Container>
            {
                role === 'owner' ? (
                    <>
                        <Audio
                            ref={audioRef}
                            muted={!audio}
                            autoPlay
                        />
                        <StyledWebcam
                            ref={videoRef}
                            playsInline
                            audio={true}
                            muted={true}
                            mirrored={facingMode === 'user'}
                            videoConstraints={{
                                aspectRatio: 16 / 9,
                                facingMode: facingMode
                            }}
                            onUserMedia={data => {
                                stream.current = data
                            }}
                            onUserMediaError={error => {
                                console.log(error)
                            }}
                        />
                        {
                            userId ? (
                                <Footer>
                                    <MenuButton onClick={shareTranslate}>
                                        <IoMdShare style={{ fontSize: 22, color: '#2B2D35', marginRight: 3 }} />
                                    </MenuButton>
                                    <TranslateButton onClick={onTranslate}>
                                        {
                                            !translate ? <OnStream /> : <BsPauseFill style={{ fontSize: 45, color: '#fff' }} />
                                        }
                                    </TranslateButton>
                                    <MenuButton onClick={flipCamera}>
                                        <IoMdReverseCamera style={{ fontSize: 24, color: '#2B2D35' }} />
                                    </MenuButton>
                                </Footer>
                            ) : null
                        }
                    </>
                ) : (
                    <>
                        <Video muted={false} ref={videoRef} autoPlay playsInline />
                        {
                            translate ? (
                                <Footer>
                                    <TranslateButton active={audio} onClick={onAudio}>
                                        {
                                            audio ? <AiFillAudio style={{ fontSize: 30, color: '#fff' }} /> : <AiOutlineAudioMuted style={{ fontSize: 30, color: '#2B2D35' }} />
                                        }
                                    </TranslateButton>
                                </Footer>
                            ) : <Poster />
                        }
                    </>
                )
            }
        </Container>
    )
}

export default Room
import { Button, Modal, Popconfirm, Table, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { DELETE_ONE_CITY, FIND_MANY_CITY } from '../../gqls/city'
import { useUser } from '../../utils/hooks'
import styled from 'styled-components'

const limit = 50
const City = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)
  const [recordState, setRecordState] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    if (!user?.role?.canChangeRole) navigate(-1)
  })

  const { data, loading, refetch } = useQuery(FIND_MANY_CITY, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const [deleteOneCity] = useMutation(DELETE_ONE_CITY,{
    onCompleted:()=>{
      navigate('/')
      navigate('/city')
    }
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyCity : []
  const total = data ? data?.findManyCity.length : 0

  const columns = [
    {
      title: 'Город',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Локация',
      dataIndex: 'location',
      key: 'location',
      render: (location) => {return `${location?.lalitude}, ${location?.longitude}`}
    },
  ]

  if (user?.role?.canSettings) {
    columns.push({
      title: 'Баннеры',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/ads-banner/${record.id}`}><Button>Перейти</Button></Link>,
    })
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Popconfirm title={`Вы действительно хотите удалить город ${record?.title}?`} description={`Вы действительно хотите удалить город ${record?.title}?`} onConfirm={async()=>{await deleteOneCity({variables:{where:{id:record?.id}}}); message.success('Вы удалили город'); refetch()}} onCancel={()=>{}} icon={<QuestionCircleOutlined style={{ color: 'red' }} />} okText="Да" cancelText="Нет"><Button danger>Удалить</Button></Popconfirm>,
    })
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/city/update/${record.id}`}><Button>Обновить</Button></Link>,
    })
  }

  const getButtons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <NavButton>{user?.role?.canChangeRole && <Link to={`/city/roles`}>Роли</Link>}</NavButton> */}
      {user?.role?.canSettings && <Link to={`/city/create`}>Добавить</Link>}
    </div>
  )

  return (
    <>
      <Top title='Города' action={getButtons()} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
      <Modal title="Вы действительно хотите удалить этот город ?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>ВЫ ДЕЙСТВИТЕЛЬНО ХОТИТЕ УДАЛИТЬ ГОРОД: {record?.title}</p>
      </Modal>
    </>
  )
}

const NavButton = styled.div`
  padding: 5px 10px;
  margin: 0 10px;
  border-right: 1px solid #d9d9d9;
`
export default City

import { gql } from "@apollo/client"

export const FIND_UNIQUE_REVIEW = gql`
	query(
		$where: ReviewWhereUniqueInput!
	){
		findUniqueReview(
			where: $where
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
	}
`
export const FIND_FIRST_REVIEW = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ReviewScalarFieldEnum]
	){
		findFirstReview(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
	}
`
export const FIND_MANY_REVIEW = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ReviewScalarFieldEnum]
	){
		findManyReview(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
		findManyReviewCount(
			where: $where
		)
	}
`
export const CREATE_ONE_REVIEW = gql`
	mutation(
		$data: ReviewCreateInput!
	){
		createOneReview(
			data: $data
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
	}
`
export const UPDATE_ONE_REVIEW = gql`
	mutation(
		$data: ReviewUpdateInput!
		$where: ReviewWhereUniqueInput!
	){
		updateOneReview(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
	}
`
export const DELETE_ONE_REVIEW = gql`
	mutation(
		$where: ReviewWhereUniqueInput!
	){
		deleteOneReview(
			where: $where
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
	}
`
export const UPSERT_ONE_REVIEW = gql`
	mutation(
		$where: ReviewWhereUniqueInput!
		$create: ReviewCreateInput!
		$update: ReviewUpdateInput!
	){
		upsertOneReview(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
				rejectMessage
				canBradcast
				working
				balance
				user{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				userId
			}
			babySitterId
			client{
				id
				createdAt
				updatedAt
				block
				delete
				name
				email
				birthday
				childrens
				rating
				phone
				location
				pushIds
			}
			userId
			order{
				id
				createdAt
				updatedAt
				from
				to
				startTime
				childrens
				comment
				price
				paymentMethod
				status
				client{
					id
					createdAt
					updatedAt
					block
					delete
					name
					email
					birthday
					childrens
					rating
					phone
					location
					pushIds
				}
				clientId
				babySitter{
					id
					createdAt
					updatedAt
					block
					delete
					name
					surname
					birthday
					about
					advantage
					idImage
					rating
					status
					rejectMessage
					canBradcast
					working
				}
				babySitterId
			}
			orderId
			author
			text
			rating
		}
	}
`
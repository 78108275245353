import { gql } from "@apollo/client"

export const FIND_UNIQUE_HELP = gql`
	query(
		$where: HelpWhereUniqueInput!
	){
		findUniqueHelp(
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
export const FIND_FIRST_HELP = gql`
	query(
		$where: HelpWhereInput
		$orderBy: [HelpOrderByWithRelationInput]
		$cursor: HelpWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [HelpScalarFieldEnum]
	){
		findFirstHelp(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
export const FIND_MANY_HELP = gql`
	query(
		$where: HelpWhereInput
		$orderBy: [HelpOrderByWithRelationInput]
		$cursor: HelpWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [HelpScalarFieldEnum]
	){
		findManyHelp(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
export const CREATE_ONE_HELP = gql`
	mutation(
		$data: HelpCreateInput!
	){
		createOneHelp(
			data: $data
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
export const UPDATE_ONE_HELP = gql`
	mutation(
		$data: HelpUpdateInput!
		$where: HelpWhereUniqueInput!
	){
		updateOneHelp(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
export const DELETE_ONE_HELP = gql`
	mutation(
		$where: HelpWhereUniqueInput!
	){
		deleteOneHelp(
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
export const UPSERT_ONE_HELP = gql`
	mutation(
		$where: HelpWhereUniqueInput!
		$create: HelpCreateInput!
		$update: HelpUpdateInput!
	){
		upsertOneHelp(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			question
			answer
			category{
				id
				createdAt
				updatedAt
				weight
				_count{
					help
				}
			}
			categoryId
		}
	}
`
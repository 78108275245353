import styled from "styled-components";
import { Form as AntForm, Input, Button, message, Select, Checkbox } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { LoadingView, Top } from "../../components";
import { UPDATE_ONE_BABY_SITTER, FIND_UNIQUE_BABY_SITTER } from "../../gqls";
import { useState } from "react";

const { Option } = Select

const Form = styled(AntForm)`
  max-width: 600px;
`;
const rules = {
  required: {
    required: true,
    message: "Обязательное поле",
  },
};

const EditSitter = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const [school, setSchool] = useState(false)
  const [insurance, setInsurance] = useState(false)

  const { loading } = useQuery(FIND_UNIQUE_BABY_SITTER, {
    variables: {
      where: { id },
    },
    onCompleted: ({
      findUniqueBabySitter: {
        rating,
        advantage,
        about,
        name,
        middlename,
        surname,
        balance,
        league,
        school,
        insurance,
        videoCard
      },
    }) => {
      setSchool(school)
      setInsurance(insurance)
      form.setFieldsValue({
        about,
        advantage,
        name,
        middlename,
        surname,
        balance,
        league,
        rating: parseFloat(rating).toFixed(2),
        videoCard
      });
    },
  });

  const [updateClient, { loading: updateLoading }] = useMutation(
    UPDATE_ONE_BABY_SITTER,
    {
      onCompleted: () => {
        navigate("/baby-sitter");
        message.success("Няня обновлена");
      },
      onError: (e) => {},
    }
  );

  const handleSubmit = ({
    rating,
    advantage,
    about,
    name,
    middlename,
    surname,
    balance,
    league,
    videoCard
  }) => {
    updateClient({
      variables: {
        where: { id },
        data: {
          rating: { set: parseFloat(rating) },
          advantage: { set: advantage },
          about: { set: about },
          name: { set: name },
          middlename: { set: middlename },
          surname: { set: surname },
          league: { set: league },
          school: { set: school },
          insurance: { set: insurance },
          videoCard: { set: videoCard },
          balance: { set: parseFloat(balance) },
        },
      },
    });
  };

  return (
    <>
      <Top title={"Редактирование няни"} />
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name={"surname"} label="Фамилия">
          <Input placeholder="Введите фамилию" />
        </Form.Item>
        <Form.Item name={"name"} label="Имя">
          <Input placeholder="Введите имя" />
        </Form.Item>
        <Form.Item name={"middlename"} label="Отчество">
          <Input placeholder="Введите отчество" />
        </Form.Item>
        <Form.Item name={"about"} label="Обо мне">
          <Input placeholder="Введите информацию о няне" />
        </Form.Item>
        <Form.Item name={"advantage"} label="Преимущества">
          <Input placeholder="Введите преимущества" />
        </Form.Item>

        <Form.Item name={"league"} label="Ранг">
            <Select placeholder='Выберите роль'>
              {['NONE', 'BRONZE', 'SILVER', 'GOLD'].map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
        </Form.Item>

        <Form.Item name={"school"} label="Школа нянь">
          <Checkbox defaultChecked={school} onChange={()=>setSchool(!school)} />
        </Form.Item>
        <Form.Item name={"insurance"} label="Страхование">
          <Checkbox defaultChecked={insurance} onChange={()=>setInsurance(!insurance)} />
        </Form.Item>
        <Form.Item name={"videoCard"} label="Ссылка на видео-визитку">
          <Input placeholder="https://www.youtube.com/..." />
        </Form.Item>

        <Form.Item name={"balance"} label="Баланс">
          <Input placeholder="Баланс" type="number" step={0.01} />
        </Form.Item>
        <Form.Item name={"rating"} rules={[rules.required]} label="Рейтинг">
          <Input
            placeholder="Введите новый рейтинг"
            type="number"
            step={0.01}
          />
        </Form.Item>
        <Button loading={updateLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form>
      <LoadingView loading={loading} />
    </>
  );
};

export default EditSitter;

import { gql } from "@apollo/client"

export const FIND_MANY_FAQ = gql`
	query(
		$where: FAQWhereInput
	){
		findManyFAQ(
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
            type
		}
	}
`

export const FIND_UNIQUE_FAQ = gql`
	query(
		$where: FAQWhereUniqueInput!
	){
		findUniqueFAQ(
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
            type
		}
	}
`

export const CREATE_ONE_FAQ = gql`
	mutation(
		$data: FAQCreateInput!
	){
		createOneFAQ(
			data: $data
		){
			id
			createdAt
			updatedAt
			question
			answer
            type
		}
	}
`
export const UPDATE_ONE_FAQ = gql`
	mutation(
		$data: FAQUpdateInput!
		$where: FAQWhereUniqueInput!
	){
		updateOneFAQ(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
            type
		}
	}
`
export const DELETE_ONE_FAQ = gql`
	mutation(
		$where: FAQWhereUniqueInput!
	){
		deleteOneFAQ(
			where: $where
		){
			id
			createdAt
			updatedAt
			question
			answer
            type
		}
	}
`
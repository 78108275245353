import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/ru_RU";

import { GlobalStyles } from "./components";

import moment from "moment";
import "moment/locale/ru";

import apolloClient from "./utils/apollo";

import Layout from "./pages/layout";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";

import UsersList from "./pages/client";
import SingleUser from "./pages/client/single";
import EditUser from "./pages/client/edit";

import BabySittersList from "./pages/baby-sitter";
import SingleSitter from "./pages/baby-sitter/single";
import Requests from "./pages/baby-sitter/request";
import SingleSRequest from "./pages/baby-sitter/request/single";
import EditSitter from "./pages/baby-sitter/edit";

import HelpList from "./pages/help";
import AddHelpCategory from "./pages/help/add";
import SingleHelpCategory from "./pages/help/single";

import OrderList from "./pages/order";

import Settings from "./pages/settings";
import Room from "./pages/room";

import Policy from "./pages/docs/policy";
import Agreement from "./pages/docs/agreement";
import Processing from "./pages/docs/processing";

import Push from "./pages/push";

import Admins from "./pages/admins";
import CreateOneAdmin from "./pages/admins/create";
import UpdateAdmin from "./pages/admins/update";

import Roles from "./pages/roles";
import CreateRole from "./pages/roles/create";
import UpdateRole from "./pages/roles/update";

import City from "./pages/city";
import CreateOneCity from "./pages/city/create";
import UpdateOneCity from "./pages/city/update";
import OfferList from "./pages/offer";
import ActiveStreams from "./pages/active-streams";
import FAQ from "./pages/faq";
import CreateFAQ from "./pages/faq/create";
import UpdateFAQ from "./pages/faq/update";
import CancelReasons from "./pages/cancel-reasons";
import CreateCancelReasons from "./pages/cancel-reasons/create";
import UpdateCancelReasons from "./pages/cancel-reasons/update";
import AdsBanner from "./pages/ads-banner";
import CreateAdsBanner from "./pages/ads-banner/create";
import OfferComments from "./pages/offer-comments";
import CreateOfferComments from "./pages/offer-comments/create";
import UpdateOfferComments from "./pages/offer-comments/update";
import ClientFavoriteList from "./pages/orderFavorite";
import OfferFavorite from "./pages/offerFavorite";

moment.locale("ru");

const App = () => {
  return (
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider locale={locale}>
          <Router>
            <Routes>
              <Route
                path="/"
                exact
                element={
                  <Layout roles={["all"]}>
                    <HomePage />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route path="/login" exact element={<LoginPage />} />
              <Route
                path="/client"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UsersList />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/client/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <SingleUser />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/client/edit/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <EditUser />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/baby-sitter"
                exact
                element={
                  <Layout roles={["all"]}>
                    <BabySittersList />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/baby-sitter/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <SingleSitter />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/baby-sitter/edit/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <EditSitter />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/request"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Requests />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/request/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <SingleSRequest />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/offer-comments"
                exact
                element={
                  <Layout roles={["all"]}>
                    <OfferComments />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/offer-comments/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateOfferComments />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/offer-comments/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateOfferComments />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/faq"
                exact
                element={
                  <Layout roles={["all"]}>
                    <FAQ />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/faq/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateFAQ />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/faq/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateFAQ />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/cancel-reasons"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CancelReasons />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/cancel-reasons/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateCancelReasons />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/cancel-reasons/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateCancelReasons />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/ads-banner/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <AdsBanner />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/ads-banner/create/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateAdsBanner />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/help"
                exact
                element={
                  <Layout roles={["all"]}>
                    <HelpList />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/order"
                exact
                element={
                  <Layout roles={["all"]}>
                    <OrderList />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/orderfavorite"
                exact
                element={
                  <Layout roles={["all"]}>
                    <ClientFavoriteList />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/offer/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <OfferList />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/offerfavorite/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <OfferFavorite />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/help/add"
                exact
                element={
                  <Layout roles={["all"]}>
                    <AddHelpCategory />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/help/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <SingleHelpCategory />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/settings"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Settings />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Admins />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateOneAdmin />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateAdmin />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/roles"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Roles />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/roles/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateRole />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/roles/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateRole />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/push"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Push />
                  </Layout> // default permossion is ['admin']
                }
              />
              {/*  */}
              <Route
                path="/city"
                exact
                element={
                  <Layout roles={["all"]}>
                    <City />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/active-streams"
                exact
                element={
                  <Layout roles={["all"]}>
                    <ActiveStreams />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/city/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateOneCity />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/city/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateOneCity />
                  </Layout> // default permossion is ['admin']
                }
              />
              {/*  */}
              <Route path="/room/:id" exact element={<Room />} />
              {/* <Route path="/active-streams" exact element={<ActiveStreams />} /> */}
              <Route path="/policy" exact element={<Policy />} />
              <Route path="/processing" exact element={<Processing />} />
              <Route path="/agreement" exact element={<Agreement />} />
            </Routes>
          </Router>
          <GlobalStyles />
        </ConfigProvider>
      </ApolloProvider>
    </StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import {
    Button,
    Table,
    Form as AntForm,
    Select,
    Input,
    message,
    Tag,
    Popover,
    Popconfirm,
    Modal
} from 'antd'
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"
// import IMask from 'imask'

import { Top } from '../../components'
import { FIND_FIRST_SETTINGS, FIND_MANY_ORDER, UPDATE_ONE_ORDER } from "../../gqls"
import { useRouteQuery, useNavigateSearch } from "../../utils/hooks"
import { FIND_MANY_CITY } from "../../gqls/city"
// import { ADMIN_TYPES } from "../../utils/const"

const Filters = styled(AntForm)`
    margin-bottom: 20px;

    .item {
        width: 200px;
    }
`

// const maskedPhone = IMask.createMask({
//     mask: '+7 (000) 000-00-00'
// })

const limit = 20

const ClientList = () => {
    const query = useRouteQuery()
    const { page = 1, search = "", status = undefined } = query
    const navigate = useNavigateSearch()

    const [cityState, setCityState] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCancelReasonModalOpen, setIsCancelReasonModalOpen] = useState(false);
    const [seenSittersModal, setSeenSittersModal] = useState(null);
    const [seenSitters, setSeenSitters] = useState(null);
    const [commentText, setCommentText] = useState(null);
    const [cancelReasonText, setCancelReasonText] = useState(null);

    const showModal = (text) => {
      setCommentText(text)
      setIsModalOpen(true);
    };


    const showCancelReasonModal = (text) => {
        setCancelReasonText(text)
        setIsCancelReasonModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const variables = useMemo(() => {
        const variables = { where: undefined }
        if (search) {
            variables.where = {
                OR: [
                    {
                        client: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        client: {
                            phone: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            name: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            surname: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            middlename: { contains: search, mode: 'insensitive' }
                        }
                    },
                    {
                        babySitter: {
                            user: {
                                phone: { contains: search, mode: 'insensitive' }
                            }
                        }
                    },
                ]
            }
        }
        if (status&&!cityState) {
            variables.where = {
                ...variables.where,
                status: { equals: status },
            }
        }
        if (cityState &&!status) {
            variables.where = {
                ...variables.where,
                city:{equals:cityState},
            }
        }
        if (cityState && status) {
            variables.where = {
                ...variables.where,
                city:{equals:cityState},
                status: { equals: status },
            }
        }
        variables.where = {
            ...variables.where,
            isFavourite: { equals: false },
        }

        return variables
    }, [search, status, cityState])

    const [updateOrder, { loading: updateLoading }] = useMutation(UPDATE_ONE_ORDER, {
        onCompleted: () => {
            message.success("Заказ отменен")
        }
    })

    const {data:city, loading:loadingCity} = useQuery(FIND_MANY_CITY)

    const { data, loading, refetch } = useQuery(FIND_MANY_ORDER, {
        variables: {
            ...variables,
            take: limit,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })


    const { data:settings } = useQuery(FIND_FIRST_SETTINGS, {
        fetchPolicy: 'network-only'
    })

    useEffect(()=>{
        refetch()
    }, [cityState])

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/order`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, status }) => {
        navigate("/order", { ...query, search, status })
    }

    const renderStatus = (status, order) => {
        let text = 'Новый'
        let color = 'yellow'
        if (status === 'cancelledBySitter') {
            text = 'Отменен няней'
            color = 'red'
        }
        if (status === 'cancelledByClient') {
            text = 'Отменен клиентом'
            color = 'red'
        }
        if (status === 'canceled') {
            text = 'Отменен'
            color = 'red'
        }
        if (status === 'complated') {
            text = 'Выполнен'
            color = 'green'
        }
        if (status === 'confirmed') {
            text = 'Подтвержден'
            color = 'green'
            return (
                <Popover
                    content={
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <Popconfirm
                                title='Заказ выполнен ?'
                                onConfirm={() => {
                                    updateOrder({
                                        variables: {
                                            where: { id: order.id },
                                            data: {
                                                status: { set: "complated" },
                                                babySitter:{
                                                    update:{
                                                        balance:{
                                                            set:order.babySitter.balance - (order.price*settings?.findFirstSettings?.persent)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    })
                                }}
                            >
                                <Button type="primary" style={{marginBottom:8}}>
                                    Заказ выполнен
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title='Отменить заказ?'
                                onConfirm={() => {
                                    updateOrder({
                                        variables: {
                                            where: { id: order.id },
                                            data: {
                                                status: { set: "canceled" }
                                            }
                                        }
                                    })
                                }}
                            >
                                <Button danger>
                                    Отменить заказ
                                </Button>
                            </Popconfirm>
                        </div>
                    }
                >
                    <Tag style={{ cursor: "pointer" }} color={color}>{text}</Tag>
                </Popover>
            )
        }
        if (status === 'new') {
            return (
                <Popover
                    content={
                        <Popconfirm
                            title='Отменить заказ?'
                            onConfirm={() => {
                                updateOrder({
                                    variables: {
                                        where: { id: order.id },
                                        data: {
                                            status: { set: "canceled" }
                                        }
                                    }
                                })
                            }}
                        >
                            <Button danger>
                                Отменить заказ
                            </Button>
                        </Popconfirm>
                    }
                >
                    <Tag style={{ cursor: "pointer" }} color={color}>{text}</Tag>
                </Popover>
            )
        }
        return <Tag color={color}>{text}</Tag>
    }

    const orders = useMemo(() => data ? data.findManyOrder : [], [data])
    const orderCount = useMemo(() => data ? data.findManyOrderCount : 0, [data])

    return (
        <>
            <Top
                title={`Заказы (${orderCount})`}
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    status
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/order", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                <Filters.Item name={'status'}>
                    <Select
                        placeholder="Статус заказа"
                        // onChange={data => setBlock(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/order", { ...query, status: undefined })}
                    >
                        <Select.Option value={'new'}>
                            Новый
                        </Select.Option>
                        <Select.Option value={'confirmed'}>
                            Подтвержден
                        </Select.Option>
                        <Select.Option value={'complated'}>
                            Выполнен
                        </Select.Option>
                        <Select.Option value={'canceled'}>
                            Отменен
                        </Select.Option>
                        <Select.Option value={'cancelledBySitter'}>
                            Отменен няней
                        </Select.Option>
                        <Select.Option value={'cancelledByClient'}>
                            Отменен клиентом
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
                <Filters.Item style={{marginLeft:16}} name={'city'}>
                    <Select
                        placeholder="Город"
                        onChange={data => setCityState(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/order", { ...query, status: undefined })}
                    >
                        {city?.findManyCity?.map((item, index)=>{
                            return(
                                <Select.Option key={index} value={item?.title}>
                                    {item?.title}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Filters.Item>
            </Filters>
                <Modal title="Комментарий" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <p>{commentText}</p>
                </Modal>
                <Modal title="Причина отмены" visible={isCancelReasonModalOpen} onOk={()=>setIsCancelReasonModalOpen(false)} onCancel={()=>setIsCancelReasonModalOpen(false)}>
                  <p>{cancelReasonText}</p>
                </Modal>
                <Modal title="Посмотревшие няни" visible={seenSittersModal} onOk={()=>setSeenSittersModal(false)} onCancel={()=>setSeenSittersModal(false)}>
                        <div style={{display:"flex", flexDirection:"column", gap:12}}>
                        {seenSitters && seenSitters[0] ? seenSitters?.map((item, index)=>{
                            return(
                            <Link index={index} to={`/baby-sitter/${item.babySitter.id}`}>
                                {item.babySitter.name} {item.babySitter.surname}
                            </Link>
                            )
                        }) : 'Нету'}
                        </div>
                </Modal>
            <Table
                loading={loading || updateLoading}
                rowKey={(obj) => obj.id}
                dataSource={orders}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: parseInt(page),
                    total: orderCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'Клиент',
                        dataIndex: 'client',
                        key: 'client',
                        render: (client, obj) => (
                            <Link to={`/client/${client.id}`}>
                                {client.name}
                            </Link>
                        )
                    },
                    {
                        title: 'Няня',
                        dataIndex: 'babySitter',
                        key: 'babySitter',
                        render: (babySitter, obj) => babySitter ? (
                            <Link to={`/baby-sitter/${babySitter.id}`}>
                                {babySitter.name} {babySitter.surname} {babySitter.middlename}
                            </Link>
                        ) : 'Исполнитель не найден'
                    },
                    {
                        title: 'Цена',
                        dataIndex: 'price',
                        key: 'price',
                        render: (price) => (
                            <span>{price} 🪙</span>
                        )
                    },
                    {
                        title: 'Время',
                        dataIndex: 'from',
                        key: 'from',
                        render: (from, obj) => (
                            <span>{moment(from).format("DD.MM.yyyy, HH:mm")} - {moment(obj.to).format("HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Город',
                        dataIndex: 'city',
                        key: 'city'
                    },
                    {
                        title: 'Адрес',
                        dataIndex: 'address',
                        key: 'address'
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: renderStatus
                    },
                    {
                        title: 'Причина отмены',
                        dataIndex: 'cancelReason',
                        key: 'cancelReason',
                        render: (cancelReason) => (
                            cancelReason ? <Button onClick={()=>showCancelReasonModal(cancelReason)}>Посмотреть</Button> : <div>Нету</div>
                        )
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Комментарии',
                        dataIndex: 'comment',
                        key: 'comment',
                        render: (comment) => (
                            comment ? <Button onClick={()=>showModal(comment)}>Посмотреть</Button> : <div>Нету</div>
                        )
                    },
                    {
                        title: 'Посмотревшие профиль',
                        dataIndex: 'seenOrder',
                        key: 'seenOrder',
                        render: (seenOrder) => (
                            seenOrder ? <Button disabled={!seenOrder[0] ? true : false} onClick={()=>setSeenSitters(seenOrder) || setSeenSittersModal(true)}>Посмотреть</Button> : <div>Нету</div>
                        )
                    },
                    {
                        title: 'Оффера',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => (
                            <a href={`/offer/${id}`} target="_blank">Посмотреть</a>
                        )
                    },
                ]}
            />
        </>
    )
}

export default ClientList
import { gql } from "@apollo/client"

export const FIND_MANY_CITY = gql`
	query(
		$where: CityWhereInput
		$orderBy: [CityOrderByWithRelationInput]
		$cursor: CityWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CityScalarFieldEnum]
	){
		findManyCity(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
            title
            description
            location
			costStatus
		}
	}
`

export const FIND_UNIQUE_CITY = gql`
	query(
		$where: CityWhereUniqueInput!
	){
		findUniqueCity(
			where: $where
		){
			id
			createdAt
			updatedAt
            title
            description
            location
			costStatus
		}
	}
`

export const CREATE_ONE_CITY = gql`
	mutation(
		$data: CityCreateInput!
	){
		createOneCity(
			data: $data
		){
			id
			createdAt
			updatedAt
            title
            description
            location
			costStatus
        }
	}
`
export const UPDATE_ONE_CITY = gql`
	mutation(
		$data: CityUpdateInput!
		$where: CityWhereUniqueInput!
	){
		updateOneCity(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
		}
	}
`

export const DELETE_ONE_CITY = gql`
	mutation(
		$where: CityWhereUniqueInput!
	){
		deleteOneCity(
			where: $where
		){
			id
			createdAt
			updatedAt
		}
	}
`

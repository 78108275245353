import { gql } from "@apollo/client"


export const USER_STATS = gql`
	query($where: UserWhereUniqueInput!) {
		userStats(where: $where)
	}
`
export const SUB = gql`
	subscription(
		$observer: String!
	) {
		sub(
			observer: $observer
		)
	}
`

export const PUB = gql`
	mutation(
		$observer: String!
		$data: Json!
	) {
		pub(
			observer: $observer
			data: $data
		)
	}
`

export const FIND_UNIQUE_USER = gql`
	query(
		$where: UserWhereUniqueInput!
	){
		findUniqueUser(
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			avatar
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			city{
				id
				title
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
	}
`
export const FIND_FIRST_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findFirstUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
	}
`
export const FIND_MANY_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
		findManyUserCount(
			where: $where
		)
	}
`
export const CREATE_ONE_USER = gql`
	mutation(
		$data: UserCreateInput!
	){
		createOneUser(
			data: $data
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
	}
`
export const UPDATE_ONE_USER = gql`
	mutation(
		$data: UserUpdateInput!
		$where: UserWhereUniqueInput!
	){
		updateOneUser(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
	}
`
export const DELETE_ONE_USER = gql`
	mutation(
		$where: UserWhereUniqueInput!
	){
		deleteOneUser(
			where: $where
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
	}
`
export const UPSERT_ONE_USER = gql`
	mutation(
		$where: UserWhereUniqueInput!
		$create: UserCreateInput!
		$update: UserUpdateInput!
	){
		upsertOneUser(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			block
			delete
			name
			email
			birthday
			childrens
			league
			rating
			phone
			babySitter{
				id
				createdAt
				updatedAt
				block
				delete
				name
				surname
				birthday
				about
				advantage
				idImage
				rating
				status
			}
			location
			pushIds
			appPlatform
			appVersion
			pushEnabled
			_count{
				orders
				blacklist
				reviews
			}
		}
	}
`
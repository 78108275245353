import { useMemo } from "react"
import styled from "styled-components"
import {
    Button,
    Table,
    Popover,
    Popconfirm,
    Form as AntForm,
    Select,
    Input,
    message
} from 'antd'
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"
import IMask from 'imask'

import { Top } from '../../../components'
import { FIND_MANY_BABY_SITTER, UPDATE_ONE_BABY_SITTER } from "../../../gqls"
import { useRouteQuery, useNavigateSearch, parseBoolean } from "../../../utils/hooks"
// import { ADMIN_TYPES } from "../../utils/const"

const Filters = styled(AntForm)`
    margin-bottom: 20px;

    .item {
        width: 200px;
    }
`
const PopoverMenu = styled.div`
    .menu-item {
        width: 100%;
        margin-bottom: 5px;
        :last-child {
            margin-bottom: 0;
        }
    }
`

const maskedPhone = IMask.createMask({
    mask: '+7 (000) 000-00-00'
})

const limit = 20

const Requests = () => {
    const query = useRouteQuery()
    const { page = 1, search = "", block = undefined, deleted = undefined, role = undefined, } = query
    const navigate = useNavigateSearch()

    const variables = useMemo(() => {
        const variables = {
            where: {
                delete: { equals: false },
                block: block ? { equals: parseBoolean(block) } : undefined,
                status: { equals: "moderated" }
            }
        }
        if (search && typeof search === 'string') {
            variables.where['OR'] = [
                {
                    name: { contains: search, mode: "insensitive" },
                },
                {
                    surname: { contains: search, mode: "insensitive" },
                },
                {
                    middlename: { contains: search, mode: "insensitive" },
                },
                {
                    user: {
                        phone: { contains: search, mode: "insensitive" },
                    }
                }
            ]
        }
        return variables
    }, [deleted, block, search, role])

    const [updateSitter, { loading: updateLoading }] = useMutation(UPDATE_ONE_BABY_SITTER, {
        onCompleted: () => {
            message.success("Клиент обновлен")
            refetch()
        },
        onError: e => { }
    })

    const { data, loading, refetch } = useQuery(FIND_MANY_BABY_SITTER, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/request`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, block }) => {
        navigate("/request", { ...query, search, block })
    }

    const sitters = useMemo(() => data ? data.findManyBabySitter : [], [data])
    const sitterCount = useMemo(() => data ? data.findManyBabySitterCount : 0, [data])

    return (
        <>
            <Top
                title={`Заявки (${sitterCount})`}
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    block: parseBoolean(block),
                    deleted: parseBoolean(deleted),
                    role
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/request", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                <Filters.Item name={'block'}>
                    <Select
                        placeholder="Статус блокировки"
                        // onChange={data => setBlock(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/request", { ...query, block: undefined })}
                    >
                        <Select.Option value={false}>
                            Не заблокированный
                        </Select.Option>
                        <Select.Option value={true}>
                            Заблокированный
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
            </Filters>
            <Table
                loading={loading || updateLoading}
                rowKey={(obj) => obj.id}
                dataSource={sitters}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: parseInt(page),
                    total: sitterCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'ФИО',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name, obj) => (
                            <Link to={obj.id}>
                                {name} {obj.surname} {obj.middlename}
                            </Link>
                        ),
                        sorter: (a, b) => a.name.length - b.name.length,
                        sortDirections: ['descend'],
                    },
                    {
                        title: 'Номер телефона',
                        dataIndex: 'user',
                        key: 'user',
                        render: (user) => <a href={`tel:${user.phone}`}>{maskedPhone.resolve(user.phone)}</a>
                    },
                    {
                        title: 'Рейтинг',
                        dataIndex: 'rating',
                        key: 'rating',
                        sorter: (a, b) => a.rating - b.rating,
                        render: rating => parseFloat(rating).toFixed(2)
                        // defaultSortOrder: 'descend'
                    },
                    {
                        title: 'Количество заказов',
                        dataIndex: '_count',
                        key: '_count',
                        render: (_count, obj) => _count.orders
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Действие',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status, object) => status === 'moderated' ? (
                            <Popover
                                title='Действия'
                                content={
                                    <PopoverMenu>
                                        <Popconfirm
                                            title={`Подтвердить заявку?`}
                                            disabled={false}
                                        >
                                            <Button type='primary' className="menu-item" loading={false}>
                                                Подтвердить
                                            </Button>
                                        </Popconfirm>
                                        <Button
                                            className="menu-item"
                                            loading={false}
                                            danger
                                        >
                                            Отклонить
                                        </Button>
                                    </PopoverMenu>
                                }
                            >
                                <Button>
                                    Действия
                                </Button>
                            </Popover >
                        ) : "-"
                    },
                ]}
            />
        </>
    )
}

export default Requests
import styled from "styled-components";
import { Input, Form, Button as AntButton, message, Select, Checkbox } from "antd";
import { useMutation, useQuery } from "@apollo/client";

import { Top } from "../../components";
import { SEND_PUSH_FOR_ALL } from "../../gqls";
import { FIND_MANY_CITY } from "../../gqls/city";
import { useState } from "react";

const { Option } = Select

const Button = styled(AntButton)``;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  .change-pass-lable {
    font-size: 14px;
    color: gray;
    margin-bottom: 15px;
  }
`;

const requiredRule = {
  required: true,
  message: "Обязательное поле",
};

const Push = () => {
  const [form] = Form.useForm();

  const [forAll, setForAll] = useState(false)
  const [forSitters, setForSitters] = useState(false)

  const {data:dataCity, loading:loadingCity} = useQuery(FIND_MANY_CITY)

  const [sendMessage, { loading }] = useMutation(SEND_PUSH_FOR_ALL, {
    onCompleted: () => {
      message.success("Уведомление отправлено");
      form.resetFields();
    },
    onError: (e) => {},
  });

  const handleSubmitForm = ({ message, cities, isForAll, isForClient, isForSitters, isForWorkedSitters }) => {
    sendMessage({
      variables: {
        data: { message, citiesIds:cities, isForAll, isForClient, isForSitters, isForWorkedSitters },
      },
    });
  };

  return (
    <>
      <Top title="Отправка PUSH уведомлений" />
      <Container>
        <Form
          form={form}
          onFinish={handleSubmitForm}
          layout="vertical"
          name="change_password"
        >
          <Form.Item name="message" rules={[requiredRule]} required>
            <Input.TextArea
              placeholder="Введите сообщение"
              style={{
                minHeight: 150,
              }}
            />
          </Form.Item>
          <Form.Item name='cities' label='Города' required>
            <Select mode="multiple" placeholder='Выберите города'>
              {dataCity?.findManyCity?.map((item) => (
                <Option key={item.id} value={item.id}>
                    {item?.title}
                </Option>
                ))}
            </Select>
          </Form.Item>

          
          <Form.Item valuePropName='checked' name='isForAll'>
            <Checkbox onChange={(e)=>setForAll(e.target.checked)}>Всем</Checkbox>
          </Form.Item>

          <Form.Item valuePropName='checked' name='isForClient'>
            <Checkbox disabled={!forAll}>Клиентам</Checkbox>
          </Form.Item>

          <Form.Item valuePropName='checked' name='isForSitters'>
            <Checkbox disabled={!forAll} onChange={(e)=>setForSitters(e.target.checked)}>Для нянь</Checkbox>
          </Form.Item>

          <Form.Item valuePropName='checked' name='isForWorkedSitters'>
            <Checkbox disabled={!forSitters||!forAll}>Для работающих нянь</Checkbox>
          </Form.Item>
          <div style={{marginBottom:16}}>Если включить для работающих нянь, то получат только работающие (Если вы хотите всем отправить, то две первые галочки без последнего)</div>

          {/* <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            defaultValue={["1"]}
            onChange={handleChange}
            options={options}
          /> */}
          <Button loading={loading} type="primary" htmlType="submit">
            Отправить
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Push;

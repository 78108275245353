import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
    Descriptions as Desc,
    Table,
    Button,
    Popconfirm,
    Modal,
    Form,
    Input,
    Checkbox
} from 'antd'
import IMask from 'imask'

import {
    Empty,
    LoadingView,
    Top,
    Image
} from '../../../components'
import { FIND_UNIQUE_BABY_SITTER, UPDATE_ONE_BABY_SITTER } from '../../../gqls'
import { useRouteQuery, useNavigateSearch } from '../../../utils/hooks'
import moment from 'moment'

const Descriptions = styled(Desc)`
    max-width: 600px;
    font-size: 14px;

    .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        padding: 0;
        object-fit: cover;
    }
    .image-list {
        display: flex;
    }
    .image {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
`
const Controls = styled.div`
    margin-top: 15px;
    margin-bottom: 24px;
`
const DocumentImage = styled(Image)`
    width: 100px;
    height: 100px;
    object-fit: cover;
`
const Note = styled.div`
    margin-bottom: 15px;
`

const maskedPhone = IMask.createMask({
    mask: '+7 (000) 000-00-00'
})

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const SingleSitter = () => {
    const { id } = useParams()
    const query = useRouteQuery()
    const navigate = useNavigateSearch()
    const [visible, setVisible] = useState(false)
    const [documents, setDocuments] = useState([])
    const [form] = Form.useForm()

    const [updateSitter, { loading: updateLoading }] = useMutation(UPDATE_ONE_BABY_SITTER, {
        onCompleted: () => {
            navigate('/request')
        },
        onError: e => { }
    })

    const { data, loading } = useQuery(FIND_UNIQUE_BABY_SITTER, {
        variables: {
            where: { id }
        }
    })

    const sitter = useMemo(() => data ? data.findUniqueBabySitter : null, [data])

    const confirmSitter = () => {
        updateSitter({
            variables: {
                where: { id },
                data: {
                    status: { set: 'submited' },
                    rejectMessage: { set: null }
                }
            }
        })
    }

    const rejectSitter = ({ message }) => {
        let _documents = []
        if (documents.length > 0) {
            for (let doc of documents) {
                _documents.push({
                    id: doc.id
                })
            }
        }
        updateSitter({
            variables: {
                where: { id },
                data: {
                    status: { set: 'rejected' },
                    rejectMessage: { set: message },
                    documents: _documents.length > 0 ? {
                        delete: _documents
                    } : undefined
                }
            }
        })
    }

    const openMessage = () => {
        setVisible(true)
    }

    const documentTypeParser = (type) => {
        if (type === 'narcology') {
            return 'Справки от нарколога'
        }
        if (type === 'passport') {
            return 'Фото паспорта'
        }
        if (type === 'psychiatrist') {
            return 'Справка от психиатра'
        }
        if (type === 'criminal') {
            return 'Справки об отсутствии судимости'
        }
        if (type === 'medical') {
            return 'Медицинская книжка'
        }
        if (type === 'flg') {
            return 'ФЛГ'
        }
        if (type === 'qr') {
            return 'Диплом о педагогическом образовании'
        }
        return '-'
    }

    const checkDocument = (type, checked) => {
        const doc = sitter.documents.find(item => item.type === type)
        if (checked) {
            setDocuments(d => [...d, doc])
        } else {
            setDocuments(d => d.filter(item => item.type !== type))
        }
    }

    // console.log(documents)

    return (
        <>
            <Top title={`Заявка няни`} />
            {
                !loading && sitter && (
                    <>
                        <Descriptions
                            title="Информация"
                            bordered
                            column={1}
                            layout='vertical'
                            labelStyle={{
                                fontWeight: 600
                            }}
                        >
                            <Descriptions.Item label='Аватар'>
                                <Image
                                    src={`/uploads/${sitter.avatar}`}
                                    className='avatar'
                                    alt={sitter.avatar}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='ФИО'>
                                {sitter.name} {sitter.surname} {sitter.middlename}
                            </Descriptions.Item>
                            <Descriptions.Item label='Номер телефона'>
                                <a href={`tel:${sitter.user.phone}`}>
                                    {maskedPhone.resolve(sitter.user.phone)}
                                </a>
                            </Descriptions.Item>
                            <Descriptions.Item label='Дата рождения'>
                                {sitter.birthday ? moment(sitter.birthday).format("DD.MM.YYYY") + `, (${moment(sitter.birthday).fromNow(true)})` : "-"}
                            </Descriptions.Item>
                            <Descriptions.Item label='Город'>
                                {sitter?.user?.city?.title ? sitter?.user?.city?.title : 'Ещё не выбран' }
                            </Descriptions.Item>
                            <Descriptions.Item label='Обо мне'>
                                {sitter.about}
                            </Descriptions.Item>
                            <Descriptions.Item label='Мои преимущества'>
                                {sitter.advantage}
                            </Descriptions.Item>
                        </Descriptions>
                        <Controls>
                            <Popconfirm
                                title='Подтвердить?'
                                onConfirm={confirmSitter}
                            >
                                <Button
                                    type='primary'
                                    loading={updateLoading}
                                >
                                    Подтвердить
                                </Button>
                            </Popconfirm>
                            <Button
                                danger
                                style={{ marginLeft: 10 }}
                                onClick={openMessage}
                            >
                                Отклонить
                            </Button>
                        </Controls>
                        <Table
                            // loading={reviewLaoding || deleteLoading}
                            rowKey={(obj) => obj.id}
                            dataSource={sitter.documents}
                            scroll={{ x: 600 }}
                            size={window.innerWidth < 500 ? 'small' : 'large'}
                            columns={[
                                {
                                    dataIndex: 'type',
                                    key: 'type',
                                    render: (type) => <Checkbox onChange={(e) => checkDocument(type, e.target.checked)} />
                                },
                                {
                                    title: 'Тип',
                                    dataIndex: 'type',
                                    key: 'type',
                                    render: (type) => documentTypeParser(type)
                                },
                                {
                                    title: 'Фото',
                                    dataIndex: 'image',
                                    key: 'image',
                                    render: (image) => <DocumentImage src={`/uploads/${image}`} />,
                                    // width: 100
                                },
                                {
                                    title: 'Дата выдачи',
                                    dataIndex: 'date',
                                    key: 'date',
                                    render: (date) => date ? moment(date).format("DD MMMM yyyy") : '-',
                                },
                            ]}
                        />
                    </>
                )
            }
            {
                !loading && !sitter && (
                    <Empty
                        description='Заявка не найдена'
                        onButtonClick={() => navigate('/request')}
                        buttonText='К заявкам'
                    />
                )
            }
            <LoadingView loading={loading} />
            <Modal
                title='Напишите причину отказа'
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={form.submit}
                okButtonProps={{ loading: updateLoading }}
                cancelButtonProps={{ loading: updateLoading }}
            >
                <Note>Если проблема в документах, отметьте те документы которые нужно отправить повторно перед тем как отклонить заявку</Note>
                <Form
                    form={form}
                    onFinish={rejectSitter}
                    layout='vertical'
                >
                    <Form.Item
                        name={'message'}
                        rules={[rules.required]}
                        label='Сообщение'
                    >
                        <Input.TextArea
                            placeholder='Введите текст'
                            style={{ minHeight: 200 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default SingleSitter
import { gql } from "@apollo/client"

export const FIND_UNIQUE_CANCEL_REASONS = gql`
	query(
		$where: CancelReasonsWhereUniqueInput!
	){
		findUniqueCancelReasons(
			where: $where
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
export const FIND_FIRST_CANCEL_REASONS = gql`
	query(
		$where: CancelReasonsWhereInput
		$orderBy: [CancelReasonsOrderByWithRelationInput]
		$cursor: CancelReasonsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CancelReasonsScalarFieldEnum]
	){
		findFirstCancelReasons(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
export const FIND_MANY_CANCEL_REASONS = gql`
	query(
		$where: CancelReasonsWhereInput
		$orderBy: [CancelReasonsOrderByWithRelationInput]
		$cursor: CancelReasonsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CancelReasonsScalarFieldEnum]
	){
		findManyCancelReasons(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
export const CREATE_ONE_CANCEL_REASONS = gql`
	mutation(
		$data: CancelReasonsCreateInput!
	){
		createOneCancelReasons(
			data: $data
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
export const UPDATE_ONE_CANCEL_REASONS = gql`
	mutation(
		$data: CancelReasonsUpdateInput!
		$where: CancelReasonsWhereUniqueInput!
	){
		updateOneCancelReasons(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
export const DELETE_ONE_CANCEL_REASONS = gql`
	mutation(
		$where: CancelReasonsWhereUniqueInput!
	){
		deleteOneCancelReasons(
			where: $where
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
export const UPSERT_ONE_CANCEL_REASONS = gql`
	mutation(
		$where: CancelReasonsWhereUniqueInput!
		$create: CancelReasonsCreateInput!
		$update: CancelReasonsUpdateInput!
	){
		upsertOneCancelReasons(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			title
			type
		}
	}
`
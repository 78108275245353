import styled from 'styled-components'
import { Form as AntForm, Input, Button, message, Select } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import {
    Top
} from '../../components'
import { CREATE_ONE_HELP_CATEGORY } from '../../gqls'
import { useUser } from '../../utils/hooks'

const Form = styled(AntForm)`
    max-width: 600px;
`
const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddHelpCategory = () => {
    const { user } = useUser()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [createAdmin, { loading }] = useMutation(CREATE_ONE_HELP_CATEGORY, {
        onCompleted: ({ createOneHelpCategory }) => {
            form.resetFields()
            message.success("Категория добавлена")
            navigate(`/help/${createOneHelpCategory.id}`)
        },
        onError: e => { }
    })

    const handleSubmit = ({ title }) => {
        createAdmin({
            variables: {
                data: {
                    title
                }
            }
        })
    }

    return (
        <>
            <Top title={"Добавление категории вопроса"} />
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    payerStatus: 'default'
                }}
            >
                <Form.Item
                    name={"title"}
                    rules={[rules.required]}
                    label="Заголовок"
                >
                    <Input placeholder='Введите заголовок' />
                </Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddHelpCategory
import { useMemo, useCallback, useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import {
    Button,
    Table,
    Switch,
    Form as AntForm,
    Select,
    Input,
    message
} from 'antd'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { MenuOutlined } from '@ant-design/icons';

import { Top } from '../../components'
import { useRouteQuery, useNavigateSearch } from '../../utils/hooks'
import { FIND_MANY_HELP_CATEGORY, CHANGE_SORT, DELETE_ONE_HELP_CATEGORY } from '../../gqls'

const SortableItem = SortableElement(props => <tr {...props} />)
const SortableBody = SortableContainer(props => <tbody {...props} />)
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)

const limit = 20

const HelpList = () => {
    const query = useRouteQuery()
    const { page = 1 } = query
    const navigate = useNavigateSearch()
    const [categories, setCategories] = useState([])

    const [changeSort, { loading: changeLoading }] = useMutation(CHANGE_SORT)
    const [deleteOneHelp] = useMutation(DELETE_ONE_HELP_CATEGORY)

    const { data, loading, refetch } = useQuery(FIND_MANY_HELP_CATEGORY, {
        variables: {
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                weight: 'asc'
            }
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyHelpCategory }) => {
            setCategories(structuredClone(findManyHelpCategory))
        }
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/help`, { ...query, page: current })
    }

    // const categories = useMemo(() => data ? data.findManyHelpCategory : [], [data])
    const categoriesCount = useMemo(() => data ? data.findManyHelpCategoryCount : [], [data])

    const DraggableContainer = props => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    )

    const DraggableBodyRow = useCallback(({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = categories.findIndex(x => x.weight === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    }, [categories])

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newWeight = categories[newIndex].weight
            const oldWeight = categories[oldIndex].weight
            console.log(oldWeight, newWeight)
            const newCategories = [...categories]
            const movedItem = categories[oldIndex]
            newCategories.splice(oldIndex, 1)
            newCategories.splice(newIndex, 0, movedItem)
            setCategories(newCategories)
            changeSort({
                variables: {
                    where: {
                        oldWeight,
                        newWeight
                    }
                }
            })
        }
    }

    const deleteHelp = (id) => {
        let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
        if (isRight) deleteOneHelp({ variables: { where: { id } }, 
            onCompleted:()=>{message.success('Успешно удалено')
            refetch()}
        })
      }

    return (
        <>
            <Top
                title={'Категории'}
                action={
                    <Link to='add'>
                        <Button>
                            + Добавить
                        </Button>
                    </Link>
                }
            />
            <Table
                loading={loading || changeLoading}
                dataSource={categories}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: parseInt(page),
                    total: categoriesCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        dataIndex: 'sort',
                        className: 'drag-visible',
                        render: () => <DragHandle />,
                        width: 50
                    },
                    {
                        title: 'Заголовок',
                        dataIndex: 'title',
                        key: 'title',
                        render: (title, obj) => (
                            <Link to={obj.id}>
                                {title}
                            </Link>
                        )
                    },
                    {
                        dataIndex: 'id',
                        render: (id) => <button onClick={()=>deleteHelp(id)} style={{color:"red", border:0, backgroundColor:"white"}}>Удалить</button>,
                        width: 50
                    },
                    
                ]}
                rowKey={"weight"}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
        </>
    )
}

export default HelpList
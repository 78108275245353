import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import {
    Descriptions as Desc,
    Table,
    Button,
    Popconfirm,
    Modal,
    Form,
    Input,
    message,
    Checkbox
} from 'antd'
import IMask from 'imask'

import {
    Empty,
    LoadingView,
    Top,
    Image
} from '../../components'
import { FIND_UNIQUE_BABY_SITTER, UPDATE_ONE_BABY_SITTER, FIND_MANY_ORDER_COUNT, FIND_MANY_REVIEW, DELETE_ONE_REVIEW, DELETE_ONE_DOCUMENT } from '../../gqls'
import { useRouteQuery, useNavigateSearch } from '../../utils/hooks'
import moment from 'moment'

const Descriptions = styled(Desc)`
    max-width: 600px;
    font-size: 14px;
    margin-bottom: 24px;

    .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        padding: 0;
        object-fit: cover;
    }
    .image-list {
        display: flex;
    }
    .image {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
`
const Controls = styled.div`
    margin-top: 15px;
    margin-bottom: 24px;
`
const DocumentImage = styled(Image)`
    width: 100px;
    height: 100px;
    object-fit: cover;
`
const Note = styled.div`
    margin-bottom: 15px;
`
const Title = styled.div`
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
`

const maskedPhone = IMask.createMask({
    mask: '+7 (000) 000-00-00'
})

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const limit = 10

const SingleSitter = () => {
    const { id } = useParams()
    const query = useRouteQuery()
    const navigate = useNavigateSearch()
    const [visible, setVisible] = useState(false)
    const [documents, setDocuments] = useState([])
    const [form] = Form.useForm()

    const { page = 1 } = query

    const today = moment()

    const [updateSitter, { loading: updateLoading }] = useMutation(UPDATE_ONE_BABY_SITTER, {
        onCompleted: () => {
            navigate('/request')
        },
        onError: e => { }
    })

    const { data, loading, refetch:refetchBabySitter } = useQuery(FIND_UNIQUE_BABY_SITTER, {
        variables: {
            where: { id }
        }
    })

    const { data: ordersData, loading: ordersLoading } = useQuery(FIND_MANY_ORDER_COUNT, {
        variables: {
            where: {
                status: { equals: "complated" },
                babySitterId: { equals: id }
            }
        }
    })

    const { data: reviewData, loading: reviewLaoding, refetch } = useQuery(FIND_MANY_REVIEW, {
        variables: {
            where: {
                order: {
                    babySitterId: { equals: id }
                },
                author: { equals: "client" }
            },
            take: limit,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        }
    })

    const [deleteComment, { loading: deleteLoading }] = useMutation(DELETE_ONE_REVIEW, {
        onCompleted: () => {
            message.success("Комментарий удален")
            refetch()
        },
        onError: e => { }
    })

    const [deleteDocument, { loading: deleteDocsLoading }] = useMutation(DELETE_ONE_DOCUMENT, {
        onCompleted: () => {
            message.success("Документ удален удален")
            refetchBabySitter()
        },
        onError: e => { }
    })

    const sitter = useMemo(() => data ? data.findUniqueBabySitter : null, [data])
    const ordersCount = useMemo(() => ordersData ? ordersData.findManyOrderCount : 0, [ordersData])

    const reviews = useMemo(() => reviewData ? reviewData.findManyReview : [], [reviewData])
    const reviewCount = useMemo(() => reviewData ? reviewData.findManyReviewCount : 0, [reviewData])

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/client/${id}`, { ...query, page: current })
    }

    const confirmSitter = () => {
        updateSitter({
            variables: {
                where: { id },
                data: {
                    status: { set: 'submited' },
                    rejectMessage: { set: null }
                }
            }
        })
    }

    const rejectSitter = ({ message }) => {
        let _documents = []
        if (documents.length > 0) {
            for (let doc of documents) {
                _documents.push({
                    id: doc.id
                })
            }
        }
        updateSitter({
            variables: {
                where: { id },
                data: {
                    status: { set: 'rejected' },
                    rejectMessage: { set: message },
                    documents: _documents.length > 0 ? {
                        delete: _documents
                    } : undefined
                }
            }
        })
    }

    const openMessage = () => {
        setVisible(true)
    }

    const documentTypeParser = (type) => {
        if (type === 'narcology') {
            return 'Справки от нарколога'
        }
        if (type === 'passport') {
            return 'Фото паспорта'
        }
        if (type === 'psychiatrist') {
            return 'Справка от психиатра'
        }
        if (type === 'criminal') {
            return 'Справки об отсутствии судимости'
        }
        if (type === 'medical') {
            return 'Медицинская книжка'
        }
        if (type === 'flg') {
            return 'ФЛГ'
        }
        if (type === 'qr') {
            return 'Диплом о педагогическом образовании'
        }
        return '-'
    }

    const checkDocument = (type, checked) => {
        const doc = sitter.documents.find(item => item.type === type)
        if (checked) {
            setDocuments(d => [...d, doc])
        } else {
            setDocuments(d => d.filter(item => item.type !== type))
        }
    }

    return (
        <>
            <Top title={`Заявка няни`} />
            {
                !loading && sitter && (
                    <>
                        <Descriptions
                            title="Информация"
                            bordered
                            column={1}
                            layout='vertical'
                            labelStyle={{
                                fontWeight: 600
                            }}
                        >
                            <Descriptions.Item label='Баланс няни'>
                                {sitter.balance}
                            </Descriptions.Item>
                            <Descriptions.Item label='Аватар'>
                                <Image
                                    src={`/uploads/${sitter.avatar}`}
                                    className='avatar'
                                    alt={sitter.avatar}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='ФИО'>
                            {sitter.surname} {sitter.name} {sitter.middlename}
                            </Descriptions.Item>
                            <Descriptions.Item label='Номер телефона'>
                                <a href={`tel:${sitter.user.phone}`}>
                                    {maskedPhone.resolve(sitter.user.phone)}
                                </a>
                            </Descriptions.Item>
                            <Descriptions.Item label='Дата рождения'>
                                {sitter.birthday ? moment(sitter.birthday).format("DD.MM.YYYY") + `, (${moment(sitter.birthday).fromNow(true)})` : "-"}
                            </Descriptions.Item>
                            <Descriptions.Item label='Город'>
                                {sitter?.user?.city?.title ? sitter?.user?.city?.title : 'Ещё не выбран' }
                            </Descriptions.Item>
                            <Descriptions.Item label='Обо мне'>
                                {sitter.about}
                            </Descriptions.Item>
                            <Descriptions.Item label='Мои преимущества'>
                                {sitter.advantage}
                            </Descriptions.Item>
                            <Descriptions.Item label='Ранг'>
                                {sitter.league}
                            </Descriptions.Item>
                            <Descriptions.Item label='Школа нянь'>
                                {sitter.school ? 'Есть' : 'Нет'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Страхование'>
                                {sitter.insurance ? 'Есть' : 'Нет'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Видео визитка'>
                                {sitter.videoCard ? <a target='_blank' href={sitter?.videoCard}>Видео-визитка</a> : 'Нет'}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label='Количество заказов'>
                                {sitter._count.orders}
                            </Descriptions.Item> */}
                            <Descriptions.Item label='Количество выполненных заказов'>
                                {ordersCount} / {sitter._count.orders}
                            </Descriptions.Item>
                            <Descriptions.Item label='Платформа моб приложения'>
                                {sitter?.user?.appPlatform ? sitter.user.appPlatform : 'Нет данных...'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Версия моб приложения'>
                                {sitter?.user?.appVersion ? sitter.user.appVersion : 'Нет данных...'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Пуш уведомления'>
                                {sitter?.user?.pushEnabled ? "Включен" : 'Отключен'}
                            </Descriptions.Item>
                        </Descriptions>
                        <Controls>
                            <Link to={`/baby-sitter/edit/${id}`}>
                                <Button
                                    type='primary'
                                    loading={updateLoading}
                                >
                                    Редактировать
                                </Button>
                            </Link>
                            <Button
                                danger
                                onClick={openMessage}
                                style={{ marginLeft: 10 }}
                            >
                                Повторная модерация
                            </Button>
                        </Controls>
                        <Title>Документы</Title>
                        <Table
                            // loading={reviewLaoding || deleteLoading}
                            rowKey={(obj) => obj.id}
                            dataSource={sitter.documents}
                            scroll={{ x: 600 }}
                            size={window.innerWidth < 500 ? 'small' : 'large'}
                            columns={[
                                // {
                                //     dataIndex: 'type',
                                //     key: 'type',
                                //     render: (type) => <Checkbox onChange={(e) => checkDocument(type, e.target.checked)} />
                                // },
                                {
                                    title: 'Тип',
                                    dataIndex: 'type',
                                    key: 'type',
                                    render: (type) => documentTypeParser(type)
                                },
                                {
                                    title: 'Фото',
                                    dataIndex: 'image',
                                    key: 'image',
                                    render: (image) => <DocumentImage src={`/uploads/${image}`} />,
                                    // width: 100
                                },
                                {
                                    title: 'Дата выдачи',
                                    dataIndex: 'date',
                                    key: 'date',
                                    render: (date, type) => date ? type.type=='flg' ? <div style={{color: moment(date).isSame(today.clone().subtract(1, 'year'), 'year') ? 'black' : 'red' }}>{moment(date).format("DD MMMM yyyy")}</div> : moment(date).format("DD MMMM yyyy") : '-',
                                },
                                {
                                    title: 'Действие',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 150,
                                    render: (id) => (
                                        <Popconfirm
                                            title={`Удалить документ ?`}
                                            onConfirm={() => {
                                                deleteDocument({
                                                    variables: {
                                                        where: {
                                                            id: id,
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <Button
                                                type={'danger'}
                                            >
                                                {"Удалить"}
                                            </Button>
                                        </Popconfirm>
                                    ),
                                },
                            ]}
                        />
                    </>
                )
            }
            {/*  */}
            <Top title={"Оценки пользователя"} />
                        <Table
                            loading={reviewLaoding || deleteLoading || deleteDocsLoading}
                            rowKey={(obj) => obj.id}
                            dataSource={reviews}
                            scroll={{ x: 600 }}
                            size={window.innerWidth < 500 ? 'small' : 'large'}
                            pagination={{
                                current: parseInt(page),
                                total: reviewCount,
                                pageSize: limit
                            }}
                            onChange={handleChangeTable}
                            columns={[
                                {
                                    title: 'ФИО автора',
                                    dataIndex: 'client',
                                    key: 'client',
                                    render: (client, obj) => (
                                        <Link to={`/baby-sitter/${client.id}`}>
                                            {client.name} {client.surname}
                                        </Link>
                                    ),
                                },
                                {
                                    title: 'Оценка',
                                    dataIndex: 'rating',
                                    key: 'rating',
                                },
                                {
                                    title: 'Текст',
                                    dataIndex: 'text',
                                    key: 'text',
                                    render: text => text ? text : '-'
                                },
                                {
                                    title: 'Дата создание',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    render: (createdAt, obj) => (
                                        <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                                    )
                                },
                                {
                                    title: 'Действие',
                                    width: 150,
                                    render: (_, object) => (
                                        <Popconfirm
                                            title={`Удалить комментарий?`}
                                            onConfirm={() => {
                                                deleteComment({
                                                    variables: {
                                                        where: {
                                                            id: object.id,
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <Button
                                                type={'danger'}
                                            >
                                                {"Удалить"}
                                            </Button>
                                        </Popconfirm>
                                    )
                                }
                            ]}
                        />
            {/*  */}
            {
                !loading && !sitter && (
                    <Empty
                        description='Заявка не найдена'
                        onButtonClick={() => navigate('/request')}
                        buttonText='К заявкам'
                    />
                )
            }
            <LoadingView loading={loading || ordersLoading} />
            <Modal
                title='Напишите причину повторной модерации'
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={form.submit}
                okButtonProps={{ loading: updateLoading }}
                cancelButtonProps={{ loading: updateLoading }}
            >
                <Note>Если проблема в документах, отметьте те документы которые нужно отправить повторно перед тем как отправить на повторную модерацию</Note>
                <Form
                    form={form}
                    onFinish={rejectSitter}
                    layout='vertical'
                >
                    <Form.Item
                        name={'message'}
                        rules={[rules.required]}
                        label='Сообщение'
                    >
                        <Input.TextArea
                            placeholder='Введите текст'
                            style={{ minHeight: 200 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default SingleSitter
import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_FAQ } from '../../gqls'
const limit = 50

const FAQ = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canHelp) navigate(-1)
  })

  const { data, loading } = useQuery(FIND_MANY_FAQ, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyFAQ : []
  const total = data ? data?.findManyFAQ.length : 0
  const columns = [
    {
      title: 'Вопрос',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Ответ',
      dataIndex: 'answer',
      key: 'answer',
    },
    {
      title: 'Тип (Где?)',
      dataIndex: 'type',
      key: 'type',
      filters: [
        {
          text: 'Родитель создал заказ',
          value: 'PARENT_ORDER_EXIST',
        },
        {
          text: 'Родитель нашел няню',
          value: 'PARENT_ORDER_APPLY',
        },
        {
          text: 'Няня нашла заказ',
          value: 'SITTER_ORDER_APPLY',
        },
        {
          text: 'В окне бонусов',
          value: 'BONUS_SCREEN',
        },
      ],
      filterMode: 'tree',
      onFilter: (value, record) => record.type.startsWith(value),
    },
  ]

  if (user?.role?.canHelp) {
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/faq/update/${record.id}`}>Изменить</Link>,
    })
  }

  return (
    <>
      <Top title='FAQ' action={user?.role?.canHelp && <Link to={`/faq/create`}>Добавить</Link>} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default FAQ

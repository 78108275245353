import { gql } from "@apollo/client"

export const FIND_UNIQUE_ADS_BANNER = gql`
	query(
		$where: AdsBannerWhereUniqueInput!
	){
		findUniqueAdsBanner(
			where: $where
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
export const FIND_FIRST_ADS_BANNER = gql`
	query(
		$where: AdsBannerWhereInput
		$orderBy: [AdsBannerOrderByWithRelationInput]
		$cursor: AdsBannerWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdsBannerScalarFieldEnum]
	){
		findFirstAdsBanner(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
export const FIND_MANY_ADS_BANNER = gql`
	query(
		$where: AdsBannerWhereInput
		$orderBy: [AdsBannerOrderByWithRelationInput]
		$cursor: AdsBannerWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdsBannerScalarFieldEnum]
	){
		findManyAdsBanner(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
export const CREATE_ONE_ADS_BANNER = gql`
	mutation(
		$data: AdsBannerCreateInput!
	){
		createOneAdsBanner(
			data: $data
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
export const UPDATE_ONE_ADS_BANNER = gql`
	mutation(
		$data: AdsBannerUpdateInput!
		$where: AdsBannerWhereUniqueInput!
	){
		updateOneAdsBanner(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
export const DELETE_ONE_ADS_BANNER = gql`
	mutation(
		$where: AdsBannerWhereUniqueInput!
	){
		deleteOneAdsBanner(
			where: $where
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
export const UPSERT_ONE_ADS_BANNER = gql`
	mutation(
		$where: AdsBannerWhereUniqueInput!
		$create: AdsBannerCreateInput!
		$update: AdsBannerUpdateInput!
	){
		upsertOneAdsBanner(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			title
			description
			url
			image
			type
			city{
				id
				title
			}
		}
	}
`
import { gql } from "@apollo/client"

export const FIND_UNIQUE_OFFER_COMMENTS = gql`
	query(
		$where: OfferCommentsWhereUniqueInput!
	){
		findUniqueOfferComments(
			where: $where
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
export const FIND_FIRST_OFFER_COMMENTS = gql`
	query(
		$where: OfferCommentsWhereInput
		$orderBy: [OfferCommentsOrderByWithRelationInput]
		$cursor: OfferCommentsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OfferCommentsScalarFieldEnum]
	){
		findFirstOfferComments(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
export const FIND_MANY_OFFER_COMMENTS = gql`
	query(
		$where: OfferCommentsWhereInput
		$orderBy: [OfferCommentsOrderByWithRelationInput]
		$cursor: OfferCommentsWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OfferCommentsScalarFieldEnum]
	){
		findManyOfferComments(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
export const CREATE_ONE_OFFER_COMMENTS = gql`
	mutation(
		$data: OfferCommentsCreateInput!
	){
		createOneOfferComments(
			data: $data
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
export const UPDATE_ONE_OFFER_COMMENTS = gql`
	mutation(
		$data: OfferCommentsUpdateInput!
		$where: OfferCommentsWhereUniqueInput!
	){
		updateOneOfferComments(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
export const DELETE_ONE_OFFER_COMMENTS = gql`
	mutation(
		$where: OfferCommentsWhereUniqueInput!
	){
		deleteOneOfferComments(
			where: $where
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
export const UPSERT_ONE_OFFER_COMMENTS = gql`
	mutation(
		$where: OfferCommentsWhereUniqueInput!
		$create: OfferCommentsCreateInput!
		$update: OfferCommentsUpdateInput!
	){
		upsertOneOfferComments(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			title
		}
	}
`
import { useMemo } from "react"
import styled from "styled-components"
import {
    Button,
    Table,
    Switch,
    Form as AntForm,
    Select,
    Input,
    message
} from 'antd'
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"
import IMask from 'imask'

import { Top } from '../../components'
import { FIND_MANY_USER, UPDATE_ONE_USER } from "../../gqls"
import { useRouteQuery, useNavigateSearch, parseBoolean } from "../../utils/hooks"
// import { ADMIN_TYPES } from "../../utils/const"

const Filters = styled(AntForm)`
    margin-bottom: 20px;

    .item {
        width: 200px;
    }
`

const maskedPhone = IMask.createMask({
    mask: '+7 (000) 000-00-00'
})

const limit = 20

const ClientList = () => {
    const query = useRouteQuery()
    const { page = 1, search = "", block = undefined, deleted = undefined, role = undefined, } = query
    const navigate = useNavigateSearch()

    const variables = useMemo(() => {
        const variables = {
            where: {
                delete: { equals: false },
                block: block ? { equals: parseBoolean(block) } : undefined,
            }
        }
        if (search && typeof search === 'string') {
            variables.where['OR'] = [
                {
                    name: { contains: search, mode: "insensitive" },
                },
                {
                    phone: { contains: search, mode: "insensitive" },
                }
            ]
        }
        return variables
    }, [deleted, block, search, role])

    const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_ONE_USER, {
        onCompleted: () => {
            message.success("Клиент обновлен")
            refetch()
        },
        onError: e => { }
    })

    const { data, loading, refetch } = useQuery(FIND_MANY_USER, {
        variables: {
            ...variables,
            take: 10,
            skip: (page - 1) * limit,
            orderBy: {
                createdAt: 'desc'
            }
        },
        fetchPolicy: 'network-only'
    })

    const handleChangeTable = ({ current }) => {
        // setCurrentPage(current)
        navigate(`/client`, { ...query, page: current })
    }

    const onSubmitSearch = ({ search, block }) => {
        navigate("/client", { ...query, search, block })
    }

    const users = useMemo(() => data ? data.findManyUser : [], [data])
    const userCount = useMemo(() => data ? data.findManyUserCount : 0, [data])

    return (
        <>
            <Top
                title={`Клиенты (${userCount})`}
            />
            <Filters
                layout='inline'
                onFinish={onSubmitSearch}
                initialValues={{
                    search,
                    block: parseBoolean(block),
                    deleted: parseBoolean(deleted),
                    role
                }}
            >
                <Filters.Item name={'search'}>
                    <Input
                        placeholder="Поиск"
                        allowClear
                        onChange={e => {
                            if (!e.target.value) {
                                navigate("/client", { ...query, search: '' })
                            }
                        }}
                        className='item'
                    />
                </Filters.Item>
                <Filters.Item name={'block'}>
                    <Select
                        placeholder="Статус блокировки"
                        // onChange={data => setBlock(data)}
                        className='item'
                        allowClear
                        onClear={() => navigate("/client", { ...query, block: undefined })}
                    >
                        <Select.Option value={false}>
                            Не заблокированный
                        </Select.Option>
                        <Select.Option value={true}>
                            Заблокированный
                        </Select.Option>
                    </Select>
                </Filters.Item>
                <Button
                    htmlType='submit'
                    type='primary'
                >
                    Применить
                </Button>
            </Filters>
            <Table
                loading={loading || updateLoading}
                rowKey={(obj) => obj.id}
                dataSource={users}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: parseInt(page),
                    total: userCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'ФИО',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name, obj) => (
                            <Link to={obj.id}>
                                {name}
                            </Link>
                        ),
                        sorter: (a, b) => a.name.length - b.name.length,
                        sortDirections: ['descend'],
                    },
                    {
                        title: 'Номер телефона',
                        dataIndex: 'phone',
                        key: 'phone',
                        render: (phone) => <a href={`tel:${phone}`}>{maskedPhone.resolve(phone)}</a>
                    },
                    {
                        title: 'Рейтинг',
                        dataIndex: 'rating',
                        key: 'rating',
                        sorter: (a, b) => a.rating - b.rating,
                        render: rating => parseFloat(rating).toFixed(2)
                        // defaultSortOrder: 'descend'
                    },
                    // {
                    //     title: 'Аккаунт няти',
                    //     dataIndex: 'babySitter',
                    //     key: 'babySitter',
                    //     render: (babySitter) => babySitter ? (
                    //         <Link to={`/baby-sitter/${babySitter.id}`}>
                    //             {babySitter.name}
                    //         </Link>
                    //     ) : '-'
                    // },
                    {
                        title: 'Ранг',
                        dataIndex: 'league',
                        key: 'league',
                        render: (league) => league
                    },
                    {
                        title: 'Количество заказов',
                        dataIndex: '_count',
                        key: '_count',
                        render: (_count, obj) => _count.orders
                    },
                    {
                        title: 'Дата создание',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt, obj) => (
                            <span>{moment(createdAt).format("DD.MM.yyyy HH:mm")}</span>
                        )
                    },
                    {
                        title: 'Бан',
                        dataIndex: 'block',
                        key: 'block',
                        render: (block, object) => (
                            <Switch
                                size='small'
                                onChange={(value) => {
                                    updateUser({
                                        variables: {
                                            where: {
                                                id: object.id,
                                            },
                                            data: {
                                                block: { set: value }
                                            }
                                        }
                                    })
                                }}
                                defaultChecked={block}
                            />
                        )
                    },
                ]}
            />
        </>
    )
}

export default ClientList
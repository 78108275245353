import { gql } from "@apollo/client"

export const FIND_MANY_ADMIN_ROLE = gql`
	query(
		$where: AdminRoleWhereInput
		$orderBy: [AdminRoleOrderByWithRelationInput]
		$cursor: AdminRoleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminRoleScalarFieldEnum]
	)
    {
      findManyAdminRole(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
        id
        title
        description
        canClients
        canSitters
        canRequests
        canHelp
        canSettings
        canOrders
        canNotification
        canChangeRole
      }
    }
`

export const FIND_UNIQUE_ADMIN_ROLE = gql`
  query ($where: AdminRoleWhereUniqueInput!) {
    findUniqueAdminRole(where: $where) {
      id
      title
      description
      canClients
      canSitters
      canRequests
      canHelp
      canSettings
      canOrders
      canNotification
      canChangeRole
    }
  }
`

export const CREATE_ONE_ADMIN_ROLE = gql`
  mutation ($data: AdminRoleCreateInput!) {
    createOneAdminRole(data: $data) {
      id
    }
  }
`
export const UPDATE_ONE_ADMIN_ROLE = gql`
  mutation ($where: AdminRoleWhereUniqueInput!, $data: AdminRoleUpdateInput!) {
    updateOneAdminRole(where: $where, data: $data) {
      id
    }
  }
`
export const DELETE_ONE_ADMIN_ROLE = gql`
  mutation ($where: AdminRoleWhereUniqueInput!) {
    deleteOneAdminRole(where: $where) {
      id
    }
  }
`

import { gql } from "@apollo/client"

export const FIND_MANY_HELP_CATEGORY_COUNT = gql`
	query(
		$where: HelpCategoryWhereInput
		$orderBy: [HelpCategoryOrderByWithRelationInput]
		$cursor: HelpCategoryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [HelpCategoryScalarFieldEnum]
	){
		findManyHelpCategoryCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_ADMIN_COUNT = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdminCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_USER_COUNT = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUserCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_BABY_SITTER_COUNT = gql`
	query(
		$where: BabySitterWhereInput
		$orderBy: [BabySitterOrderByWithRelationInput]
		$cursor: BabySitterWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [BabySitterScalarFieldEnum]
	){
		findManyBabySitterCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_DOCUMENT_COUNT = gql`
	query(
		$where: DocumentWhereInput
		$orderBy: [DocumentOrderByWithRelationInput]
		$cursor: DocumentWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [DocumentScalarFieldEnum]
	){
		findManyDocumentCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_ORDER_COUNT = gql`
	query(
		$where: OrderWhereInput
		$orderBy: [OrderOrderByWithRelationInput]
		$cursor: OrderWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OrderScalarFieldEnum]
	){
		findManyOrderCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_OFFER_COUNT = gql`
	query(
		$where: OfferWhereInput
		$orderBy: [OfferOrderByWithRelationInput]
		$cursor: OfferWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [OfferScalarFieldEnum]
	){
		findManyOfferCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_REVIEW_COUNT = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ReviewScalarFieldEnum]
	){
		findManyReviewCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`